<template>
  <ion-page :key="uniqueKey">
    <ion-header class="headerTop">
      <headertoolbar></headertoolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ion-header collapse="condense"> </ion-header>
      <button
        class="concursoNotification"
        :class="concursoActivo"
        @click="showContestModalButton()">
        <ion-img src="assets/ICONOS/ticket.svg"></ion-img>
        <span class="tooltip">¡Concurso Activo!</span>
      </button>
      <ion-img
        src="assets/reproductor/logo_default.jpg"
        v-if="!clientImageSrc"
        class="playerImage"
        :style="{ 'background-color': '#FFFFFF' }"></ion-img>
      <ion-img
        :src="clientImageSrc"
        v-else
        class="playerImage"
        :style="{ 'background-color': clientColor }"
        :alt="clientName + '_logo'"></ion-img>
      <!-- default -->
      <div
        class="playerImage2_out"
        v-if="!clientImageSrc"
        :style="{ 'background-color': '#FFFFFF' }">
        <div
          class="playerImage2"
          :style="{
            'background-image': 'url(assets/reproductor/logo_default.jpg)',
          }"></div>
      </div>
      <!-- imagen cliente -->
      <div
        class="playerImage2_out"
        v-else
        :style="{ 'background-color': clientColor }">
        <div
          class="playerImage2"
          :style="{ 'background-image': 'url(' + clientImageSrc + ')' }"
          :alt="clientName + '_logo'"></div>
      </div>
      <ion-grid class="reproductor_small">
        <ion-row>
          <ion-col size="8" class="songInfoCol">
            <p class="songName">
              <span>{{ title }}</span>
            </p>
            <p class="playlistName">
              <span>{{ artist }}</span>
            </p>
          </ion-col>
          <ion-col size="4" class="likeCol">
            <ion-button
              fill="clear"
              v-on:click="liked == 0 ? likeInteraction('1') : ''"
              class="likeButton"
              ><ion-icon
                :src="
                  liked == 1
                    ? 'assets/ICONOS/like.svg'
                    : 'assets/ICONOS/like-linea.svg'
                "
                name="botón like"></ion-icon
            ></ion-button>
            <!-- <ion-button fill="clear" v-else-if="liked=1" class="likeButton"><ion-icon src="assets/ICONOS/like.svg"></ion-icon></ion-button> -->
            <ion-button
              fill="clear"
              v-on:click="liked == 0 ? likeInteraction('2') : ''"
              class="dislikeButton"
              ><ion-icon
                :src="
                  liked == 2
                    ? 'assets/ICONOS/dislike.svg'
                    : 'assets/ICONOS/dislike-linea.svg'
                "></ion-icon
            ></ion-button>
            <!-- <ion-button v-on:click="next()">Next</ion-button> -->
          </ion-col>
          <ion-col size="12" class="progressBarCol">
            <ion-progress-bar
              :value="songProgress"
              buffer="1"></ion-progress-bar>
          </ion-col>
          <ion-col size="6" class="songTimeCol" style="color: white">
            <p class="songTime">
              {{ currentlyTimer ? currentlyTimer : '00:00' }}
            </p>
          </ion-col>
          <ion-col size="6" class="songTimeRemainingCol" style="color: white">
            <p class="songTimeRemaining">{{ totalTimer }}</p>
          </ion-col>
          <!-- <ion-col size="12" class="rep_nativo">
            <audio class="player_audio" controls id="audio">
                <source src="https://vybroo.net/a/music/V/VAN%20HALEN%20-%20Panama.m4a" type="audio/mpeg"/>
            </audio>
          </ion-col> -->
          <ion-col size="12" class="playPauseCol" shape="round">
            <!-- <ion-button fill="clear" class="playPauseButton volumeButton" v-on:click="showVolumeButton()" shape="round"><ion-icon src="assets/ICONOS/volume_white.svg"></ion-icon></ion-button> -->
            <ion-button
              fill="clear"
              class="playPauseButton playButton"
              v-if="!globalPlay"
              ref="playButton"
              v-on:click="play()"
              shape="round"
              ><ion-icon src="assets/ICONOS/play.svg"></ion-icon
            ></ion-button>
            <ion-button
              fill="clear"
              class="playPauseButton playButton"
              v-if="!isPlaying && globalPlay"
              ref="playButton"
              v-on:click="play()"
              shape="round"
              ><ion-icon src="assets/ICONOS/play.svg"></ion-icon></ion-button
            ><!--audios-griss.svg-->
            <ion-button
              fill="clear"
              class="playPauseButton pauseButton"
              v-else-if="globalPlay"
              ref="pauseButton"
              v-on:click="pause()"
              shape="round"
              ><ion-icon src="assets/ICONOS/pausa.svg"></ion-icon></ion-button
            ><!--audios-blanco.svg-->
            <!-- botón de opciones fijo en el sector derecho del boton de play/pause -->
            <ion-button
              fill="clear"
              class="playPauseButton optionsButton"
              id="qualityOptions"
              v-on:click="showQualityOptionsButton()"
              v-if="this.sound.controls"
              shape="round"
              title="Calidad de sonido"
              ><ion-icon src="assets/ICONOS/options2_white.svg"></ion-icon
            ></ion-button>
          </ion-col>
          <!-- Boton de next -->
          <ion-col size="12" class="nextCol">
            <ion-button
              fill="clear"
              class="nextButton"
              v-on:dblclick="next()"
              shape="round">
              Next
              <ion-img
                class="nextButtonImg"
                src="assets/ICONOS/next.png"></ion-img>
            </ion-button>
          </ion-col>
          <!-- <ion-button v-on:click="pause()">Pausar</ion-button>
            <ion-button v-on:click="play()">Play</ion-button> -->
          <!-- <ion-button v-on:click="scheduleBasic()">Schedule basic</ion-button> -->
          <!-- <ion-button v-on:click="scheduleAdvanced()">Schedule advanced</ion-button> -->
          <ion-col size="12" class="rep_nativo">
            <!-- <audio class="player_audio" controls ref="audioPlayer" id="audio">
                <source :src="playerAudioSource" type="audio/mpeg"/>
                <source src="https://vybroo.net/a/music/V/VAN%20HALEN%20-%20Panama.m4a" type="audio/mpeg"/>
            </audio> -->
          </ion-col>
        </ion-row>
      </ion-grid>
      <div class="loaderOutDiv" v-show="vBackStatus">
        <p class="vBackMessage">Servidor de Respaldo Activo</p>
        <ion-icon class="vbackimg" src="assets/ICONOS/vback.svg"></ion-icon>
        <div class="loaderr"></div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="js">
/* eslint-disable */
import {
  alertController,
  IonPage,
  IonHeader,
  IonToolbar,
  IonContent,
  IonCol,
  IonGrid,
  IonRow,
  IonButton,
  IonProgressBar,
  menuController,
  IonImg,
  IonIcon,
  modalController
} from '@ionic/vue';
import axios from 'axios';
import { defineComponent, version } from 'vue';
import { Storage } from '@capacitor/storage';
import headertoolbar from './header_toolbar.vue';
// import App from '../App.vue';
import $ from 'jquery';
import Vue from 'vue';
import { App } from '@capacitor/app';
import { BackgroundMode } from '@ionic-native/background-mode';
import { Capacitor } from '@capacitor/core';
// import { Insomnia } from '@ionic-native/insomnia';
import { Network } from '@capacitor/network';
// import { PowerManagement } from '@ionic-native/power-management';
import { Toast } from '@capacitor/toast';
import moment from 'moment-timezone';
import contestModal from './contestModal.vue';
import RepModal from './ModalRepFullView.vue';
import { NativeAudio } from '@capacitor-community/native-audio';

export default {
  name: 'Reproductor',
  components: {
    IonHeader,
    IonToolbar,
    IonContent,
    IonPage,
    IonCol,
    IonGrid,
    IonRow,
    IonButton,
    IonProgressBar,
    headertoolbar,
    IonImg,
    IonIcon,
  },
  props: ['playTest'],
  data() {
    return {
      platform: '',
      sound: {
        quality: 'm4a_32',
        controls: false,
        fileExtension: '.mp3',
        volume: 0.5,
        file: '',
        legacyFile: '',
        url: '',
      },
      isLoaded: false,
      urlSongsApi: 'https://pub-3b7d9c13a5754ebeaa0345eb87aa22c1.r2.dev/',
      uniqueKey: 1,
      videoOn: false,
      fullScreenOn: false,
      modalisopen: false,
      modalWasClosed: false,
      concursoActivo: 'concursoInactivo',
      audio: null,
      title: 'Cargando...',
      bkTitle: '',
      artist: 'Vybroo',
      bkArtist: '',
      type: 'spot',
      location: '',
      token: -1,
      previousToken: -1,
      active: false,
      lastTry: 0,
      client: { color: '', name: '', logo: 'logo.svg', mobile_logo: '' },
      imageStyle: {},
      // Variables nuevas
      showPlayButton: false,
      playerAudioSource: '',
      current: {},
      coverObject: { cover: true, animated: false },
      index: 0,
      isPlaying: false,
      currentlyTimer: "00:00",
      totalTimer: "00:00",
      songs: [],
      player: new Audio(),
      songProgress: 0,
      clientData: {},
      clientName: "",
      clientImageSrc: "",
      clientColor: "",
      liked: 0,
      muted: false,
      unmutedVolume: null,
      globalPlay: false,
      currentSucursal: "",
      currentCategoria: "0",
      currentCategoriaAux: null,
      currentVariante: "0",
      currentVarianteAux: null,
      currentDataApiRequest: {},
      playerAudioDemanda: new Audio(),
      playingAudioDemanda: false,
      playerAudioDemandaSource: '',
      playerError: false,
      enLinea: 0,
      nextRunning: false,
      urlParams: {},
      saveMusicVariant: {},
      networkStatus: {},
      listenedChanges: {},
      alertRefreshPresented: false,
      updateInfo: "2024-10-04 #1 (v1.6.1: Reupload reporte por horas por sucursal)",
      clientVersion: "1.6.0",
      contest: {

      },
      horaServer: "",
      horaLocal: "",
      horaMenor: "",
      horaActual: "",
      playErrorCount: 0,
      tokenErrorCount: 0,
      lastTokenError: 0,
      lastCategoriaError: "",
      errorReport: {},
      running500msInterval: false,
      sonara: false,
      audio_id: '0',
      ip: "",
      nextApiErrors: 0,
      vBackStatus: false,
      vBackList: [],
      vBackToken: -1,
      wakeLock: null,
      speedTest: "",

    }
  },
  methods: {
    async imHere() {
      try {


        let sucAux = 0;
        let suc = this.currentSucursal ? this.currentSucursal : 0;
        if (suc != 0) {
          let suc2 = this.clientData.identifiers.find(identifiers => identifiers.identifier == suc);
          sucAux = suc2.id;
        }


        // console.log("imHere() - sucursal: " + sucAux + " - variante: " + this.currentVariante + " - categoria: " + this.currentCategoria + " - playing: " + this.isPlaying + " - volume: " + this.player.volume * 100 + " - audio_id: " + this.audio_id + " - version: " + this.clientVersion + " - audio_name: " + this.title + " - token: " + this.token);


        let url = "api/im_here";
        if (Capacitor.getPlatform() == 'ios' || Capacitor.getPlatform() == 'android') url = "https://panel.vybroo.net/im_here";
        await axios.post(url, {
          stream: this.clientData.stream,
          suc: sucAux,
          var: this.currentVariante ? this.currentVariante : 0,
          cat: this.currentCategoria ? this.currentCategoria : 0,
          ip: this.ip,
          playing: this.isPlaying,
          volume: this.player.volume * 100,
          audio_id: this.audio_id,
          version: this.clientVersion,
          audio_name: this.title,
          token: this.token,
        })
          .then(r => {
            // console.log("---im_here: r.data:", r.data);
          }).catch(error => {
            console.log("im_here: 1Error en función validateToken de Conexiones: " + error.message);
          });
      } catch (error) {
        console.log("im_here: 2Error en función validateToken de Conexiones: " + error.message);
      }
    },

    async speedTestRun() {
      try {
        const downloadSize = 116720; // bytes
        const downloadUrl = 'https://pub-3b7d9c13a5754ebeaa0345eb87aa22c1.r2.dev/images/vybroo-test.jpg';

        const downloadSpeed = (index) => {
          return new Promise((resolve, reject) => {
            setTimeout(() => {
              const startTime = (new Date()).getTime();
              const download = new Image();
              const cacheBuster = "?nnn=" + startTime;

              download.onload = () => {
                const endTime = (new Date()).getTime();
                const duration = (endTime - startTime) / 1000;
                const bitsLoaded = downloadSize * 8;
                const speedBps = (bitsLoaded / duration).toFixed(2);
                const speedKbps = (speedBps / 1024).toFixed(2);
                // console.log(`Connection Speed Index: ${index}\n${speedBps} bps\n${speedKbps} kbps`);
                resolve(parseFloat(speedKbps));
              };

              download.onerror = () => {
                console.log(`Error en la descarga del paquete: ${index}`);
                reject(new Error(`Error en la descarga del paquete: ${index}`));
              };

              download.src = downloadUrl + cacheBuster;
            }, index * 1000);
          });
        };

        const promises = [];
        for (let index = 0; index < 10; index++) {
          promises.push(downloadSpeed(index));
        }

        const results = await Promise.all(promises);
        const suma = results.reduce((acc, speed) => acc + speed, 0);
        const promedio = suma / results.length;
        // console.log(`Promedio: ${promedio} kbps`);
        const speedMbps = (promedio / 1024).toFixed(2);
        this.speedTest = `${speedMbps} Mbps`;

            var  SPCurrentVariante = this.currentVariante ? this.currentVariante : 0 ;
            var  SPCurrentCategoria = this.currentCategoria ? this.currentCategoria : 0 ;

        var SPText = "SPEEDTEST | Canal: " + this.clientData.stream +
            " | " + this.speedTest +
            " | Hora local: " + this.horaActual +
            " | var: " + SPCurrentVariante +
            " | cat: " + SPCurrentCategoria +
            " | ip: " + this.ip +
            " |";
        // console.log(SPText);
        const dataParameters = {
          chat: '-715319368',
          text: SPText
        };

          await axios.post("/api/sendmessage", dataParameters)
          .then(data => {
            console.log("%cSppedTest Enviado ✅", "color: green; font-weight: bold; background-color: #eee; padding: 5px; border-radius: 5px; font-size: 14px; border: 2px solid green;");
            console.log("Velocidad de internet: " + this.speedTest);
          })
          .catch(error => {
            console.error("Error en sendStats:", error.toString());
          });


      } catch (error) {
        console.log(`Error al medir la velocidad de internet: ${error}`);
      }
    },
    changeVolume(volume) {
      this.player.volume = volume;
      // console.log("changeVolume("+this.player.volume+")");
    },
    getHighestSongQuality(song_id, song_file) {
      // post a la api para obtener la calidad mas alta de una cancion
      // console.log("getHighestSongQuality(" + song_id + ")");
      try {
        let url = "api/get_song_quality/";
        if (Capacitor.getPlatform() == 'ios' || Capacitor.getPlatform() == 'android') url = "https://panel.vybroo.net/get_song_quality_m";
        axios.post(url, {
          songId: song_id
        }, {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        })
          .then(response => {
            // console.log("getHighestSongQuality() response: ", response);
            if (response.data.success == true && response.data.quality.length > 0) {
              if (response.data.quality[0].bit_r == "320") {
                console.log("FIJANDO CALIDAD A 320")
                this.sound.quality = "mp3_320";
                this.sound.fileExtension = ".mp3";
                this.buildAudioUrl(response.data.quality[0].location);
              }
              if (response.data.quality[0].bit_r == "128") {
                console.log("FIJANDO CALIDAD A 128")
                this.sound.quality = "mp3_128";
                this.sound.fileExtension = ".mp3";
                this.buildAudioUrl(response.data.quality[0].location);
              }
              if (response.data.quality[0].bit_r == "32") {
                console.log("FIJANDO CALIDAD A 32")
                this.sound.quality = "m4a_32";
                this.sound.fileExtension = ".m4a";
                this.buildAudioUrl(song_file);
              }
              // this.sound.quality = response.data.quality;
              // this.sound.fileExtension = response.data.fileExtension;
              // this.buildAudioSource();
            } else {
              console.log("FIJANDO CALIDAD A 320")
              this.sound.quality = "mp3_320";
              this.sound.fileExtension = ".mp3";
              this.buildAudioUrl(song_file);
            }
          })
          .catch(error => {
            console.log("FIJANDO CALIDAD A 320")
            this.sound.quality = "mp3_320";
            this.sound.fileExtension = ".mp3";
            this.buildAudioUrl(song_file);
          });
      } catch (error) {
        console.log("getHighestSongQuality() error: ", error);
        console.log("FIJANDO CALIDAD A 320")
        this.sound.quality = "mp3_320";
        this.sound.fileExtension = ".mp3";
        this.buildAudioUrl(song_file);
      }
    },
    showQualityOptionsButton() {
      //mostrar menu contextual de opciones de calidad
      console.log("showQualityOptionsButton()");
      //opciones de calidad con el formato y bitrate oscurecidos, mostrando el seleccionado actualmente con radio button
      const options = [
        {
          title: "Calidad de audio",
          subTitle: "Selecciona la calidad de audio que deseas escuchar",
          text: "Bajo (M4A 32kb/s)",
          soundQuality: "m4a_32",
          handler: () => {
            this.sound.quality = "m4a_32";
            this.sound.fileExtension = ".m4a";
            this.buildAudioUrl();
            this.player.currentTime = this.sound.previousTime;
          }
        },
        {
          text: "Estándar (MP3 128kb/s)",
          soundQuality: "mp3_128",
          handler: () => {
            this.sound.quality = "mp3_128";
            this.sound.fileExtension = ".mp3";
            this.buildAudioUrl();
            this.player.currentTime = this.sound.previousTime;
          }
        },
        {
          text: "Superior (MP3 320kb/s)",
          soundQuality: "mp3_320",
          handler: () => {
            this.sound.quality = "mp3_320";
            this.sound.fileExtension = ".mp3";
            this.buildAudioUrl();
            this.player.currentTime = this.sound.previousTime;
          }
        },
        {
          text: "Cancelar",
          role: "cancel"
        }
      ];
      this.presentActionSheet(options);
    },
    presentActionSheet(options) {
      console.log("presentActionSheet()");
      const actionSheet = document.createElement("ion-action-sheet");
      actionSheet.header = "Calidad de sonido";
      actionSheet.buttons = options;

      actionSheet.buttons.forEach((button, index) => {
        if (button.soundQuality == this.sound.quality) {
          //AGREGAR CLASE PARA QUE SE VEA SELECCIONADO
          button.cssClass = "action-sheet-selected";
        } else {
          button.cssClass = "";
        }
      });
      actionSheet.buttons.push({
        text: "Cancelar",
        role: "cancel"
      });
      document.body.appendChild(actionSheet);
      return actionSheet.present();
    },
    buildAudioUrl(fileToRebuild) {
      if (fileToRebuild) {
        fileToRebuild = fileToRebuild.startsWith("/a/") ? fileToRebuild.replace("/a/", "") : fileToRebuild;
        let split = fileToRebuild.split("/");
        //imprimir la ultima posición del array
        // console.log("Ultima posición del array: " + split[split.length - 1]);
        this.sound.legacyFile = split[split.length - 2] + "/" + split[split.length - 1];
        //Poner cada una de las palabras solo con la primera letra en mayúscula y el resto en minúscula
        let capitalized = split[split.length - 1].replace(/\w\S*/g, function (txt) { return txt.charAt(0).toLowerCase() + txt.substr(1).toLowerCase(); });
        //imprimir la ultima posición del array capitalizada
        // console.log("Ultima posición del array capitalizada: " + capitalized);
        //juntar las ultimas dos posiciones del array con un / entre medio e imprimirlo
        let stringFinal = split[split.length - 2] + "/" + capitalized;
        let stringFinalLegacy = this.sound.legacyFile;
        //reemplazar espacios por %20
        stringFinal = stringFinal.replace(/ /g, "%20");
        stringFinalLegacy = stringFinalLegacy.replace(/ /g, "%20");
        //eliminar la extensiòn del archivo
        stringFinal = stringFinal.replace(".mp3", "");
        stringFinal = stringFinal.replace(".m4a", "");
        stringFinalLegacy = stringFinalLegacy.replace(".mp3", "");
        stringFinalLegacy = stringFinalLegacy.replace(".m4a", "");

        this.sound.legacyFile = stringFinalLegacy;
        this.sound.file = stringFinal;
        // console.log("String final: " + stringFinal);
        // console.log("String final legacy: " + stringFinalLegacy);
      }
      // console.log("this.sound.quality: " + this.sound.quality);
      this.sound.quality == "m4a_32" ? this.sound.fileExtension = ".m4a" : "";
      this.sound.quality == "mp3_128" ? this.sound.fileExtension = ".mp3" : "";
      this.sound.quality == "mp3_320" ? this.sound.fileExtension = ".mp3" : "";

      //construir url de audio
      //calidad legacy
      if (this.sound.quality == "m4a_32") {
        this.sound.url = this.urlSongsApi + "music/" + this.sound.legacyFile + ".m4a";
      } else {
        this.sound.url = this.urlSongsApi + this.sound.quality + "/" + this.sound.file + this.sound.fileExtension;
      }
      // console.log("this.sound.url: " + this.sound.url);
      console.log("Reproduciendo actualmente: " + this.title + " - " + this.artist);
      this.playerAudioSource = this.sound.url;
      this.player.src = this.sound.url;
      this.play();
    },
    playIntro() {
      console.log("play() - INTRO");
      console.log("ES SONARA: " + this.sonara);
      if (this.sonara) {
        this.playerAudioSource = 'https://pub-3b7d9c13a5754ebeaa0345eb87aa22c1.r2.dev/intros/sonara_1.mp3';
        this.artist = "Sonara";
        console.log("Reproduciendo intro de Sonara");
      }else{
        this.playerAudioSource = 'https://pub-3b7d9c13a5754ebeaa0345eb87aa22c1.r2.dev/intros/intro_1.mp3';
        this.artist = "Vybroo";
        console.log("Reproduciendo intro de Vybroo");
      }

      this.player.src = this.playerAudioSource;
      this.token = -1;
      this.title = "Intro";
      this.audio_id = '0';
      this.type = "spot";
      this.emitter.emit("rep-token", this.token);
      this.emitter.emit("rep-title", this.title);
      this.emitter.emit("rep-artist", this.artist);
      this.emitter.emit("rep-type", this.type);

      this.play();
    },
    async loadApi(streamName) {
      try {
        if (this.networkStatus.connected == false) {
          console.log("Error de conexión, reintentando... (loadApi)", error);
          this.title = "Error de conexión";
          this.artist = "Reintentando...";
          this.audio_id = '0';
          this.emitter.emit("rep-title", this.title);
          this.emitter.emit("rep-artist", this.artist);
          setTimeout(() => {
            this.loadApi(this.clientData.stream);
          }, 2000);
        } else {
          // console.log("cargando api del canal: " + streamName);
          await this.getApiRequestData();
          const intro_expired = await this.intro_expired();
          const dataParameters = { stream: streamName.toLowerCase(), token: this.token, cid: this.currentSucursal, music_variant: this.currentCategoria, variant: this.currentVariante, video: this.clientData.video_enabled };
          // console.log("Parámetros loadApi:");
          // console.log(dataParameters);
          await axios.post("https://vybroo.net/api", dataParameters)
            .then(data => {
              if (data.data) {

                if (this.clientData.video_enabled) {
                  // console.log("REPRODUCIENDO VIDEO INTRO");
                  if (this.sonara) {
                    this.showVideoModal('https://vybroo.net/videos/intros/sonaraelda.mp4');
                  } else {
                    this.showVideoModal('https://vybroo.net/videos/intros/vybrooelda.mp4');
                  }
                } else {
                  if (this.clientData.play_intro && intro_expired) {
                    // console.log("REPRODUCIENDO AUDIO INTRO");
                    this.playIntro();
                    return;
                  } else {
                    // console.log("REPRODUCIENDO CANCIÓN NORMAL");
                    if (this.clientData.play_intro == false) {
                      console.log("No se reproducirá intro porque está deshabilitado desde panel");
                    }
                  }
                  this.token = Number(data.data.token);
                  this.previousToken = Number(data.data.token);
                  console.log("TOKEN: " + this.token);
                  this.type = data.data.type;
                  // console.log("TYPE: " + this.type + " desde loadApi" );
                  this.title = data.data.title ? data.data.title : 'Cargando...';
                  this.bkTitle = data.data.title ? data.data.title : 'Cargando...';
                  this.audio_id = data.data.id ? data.data.id : '0';
                  this.emitter.emit("rep-token", this.token);
                  this.emitter.emit("rep-title", this.title);
                  this.emitter.emit("rep-type", this.type);
                  this.emitter.emit("rep-artist", this.artist);
                  if (data.data.type != 'video') {

                    if (this.sonara) {
                      this.artist = data.data.artist ? data.data.artist : 'Sonara';
                      this.bkArtist = data.data.artist ? data.data.artist : 'Sonara';
                    } else {
                      this.artist = data.data.artist ? data.data.artist : 'Vybroo';
                      this.bkArtist = data.data.artist ? data.data.artist : 'Vybroo';
                    }
                    this.location = data.data.file;
                    if (data.data.type == 'time') {
                      this.playerAudioSource = this.getTimeFile();
                      this.title = 'Hora';
                      this.artist = 'Vybroo';
                      this.audio_id = '0';
                      this.player.src = this.playerAudioSource;
                    }
                    //si data.data.file comienza con "/a/" borrar eso y dejar solo el "/audios/..."
                    if (data.data.type == 'song') {
                      console.log("Reproduciendo canción...");
                      if (data.data.id) { this.getHighestSongQuality(data.data.id, data.data.file) }
                    }
                    if (data.data.type == 'spot') {
                      console.log("Reproduciendo spot...");
                      this.playerAudioSource = 'https://vybroo.net' + (data.data.file).replace(/ /g, "%20");
                      this.player.src = this.playerAudioSource;
                    }
                    this.emitter.emit("rep-title", this.title);
                    this.emitter.emit("rep-artist", this.artist);
                    // this.playerAudioSource = 'https://vybroo.net' + (data.data.file).replace(/ /g,"%20");
                    // console.log(this.playerAudioSource);
                    this.player.volume = this.sound.volume;
                    this.player.preload = "auto";
                  } else { // ES VIDEO
                    this.playerAudioSource = thus.urlSongsApi + (data.data.file).replace(/ /g, "%20");
                    this.emitter.emit("rep-VideoSource", this.playerAudioSource);
                  }

                }
                //enviar a endpoint de telegram
                this.sendStats();
                // this.getTimeFile();
              } else {
                this.presentAlertConfirm("Error", "La lista de canciones está vacía, favor de contactar a su asesor en caso de que el error persista.");
              }
            })
            .catch(error => {
              if (this.enLinea == 2) {
                console.log("Error de conexión, reintentando... (loadApi)", error);
                this.title = "Error de conexión";
                this.artist = "Reintentando...";
                this.audio_id = '0';
                this.emitter.emit("rep-title", this.title);
                this.emitter.emit("rep-artist", this.artist);
                setTimeout(() => {
                  this.loadApi(this.clientData.stream);
                }, 2000);
              }
              console.error("Error en loadApi:", error.toString());
              this.sendErrorReport("loadApi(axios)", error.toString());
              this.vBackNext();
            });
        }
      } catch (e) {
        this.presentAlertConfirm("Error en la función loadApi", e.toString());
        this.sendErrorReport("loadApi", e.toString());
      }
    },
    async sendErrorReport(funcion, error) {
      try {
        // const ip = await axios.get("https://api.ipify.org/?format=json");
        const zonaHoraria = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const zonaHorariaG = zonaHoraria.replace(/\//g, "-");
        if (this.horaActual == "") {
          const horaServer = await axios.get('/api/hora/' + zonaHorariaG);
          this.horaActual = horaServer.data.hora ? horaServer.data.hora = horaServer.data.hora : horaServer.data.hora = "No disponible";
        }

        const identifier_name = this.clientData.identifiers.find(item => item.id === this.currentSucursal);
        const identifier = identifier_name ? identifier_name.identifier : undefined;

        const music_variant_name = this.clientData.music_variants.find(item => item.id === this.currentCategoria);
        const music_variant = music_variant_name ? music_variant_name.name : undefined;

        const is_localhost_message = window.location.href.includes("localhost") ? "LOCALHOST:" : null;
        const message =
          (is_localhost_message ? is_localhost_message : "") + "\n" +
          "Error en: " + funcion + "\n" +
          "Error: " + JSON.stringify(error) + "\n" +
          "Canal: " + this.clientData.stream + "\n" +
          "Token: " + this.token + "\n" +
          "ID Sucursal: " + (this.currentSucursal != '' ? this.currentSucursal : 'Sin selección') + "\n" +
          "ID Categoría musical: " + this.currentCategoria + "\n" +
          "ID Variante de contenido: " + this.currentVariante + "\n" +
          "Zona Horaria: " + zonaHoraria + "\n" +
          "Hora local: " + this.horaActual + "\n" +
          "Navegador/OS: " + navigator.userAgent + "\n" +
          "Versión de App: " + this.updateInfo + "\n" +
          "URL: " + window.location.href;
        // "IP: " + ip.data.ip;

        const uniqueMessage =
          (is_localhost_message ? is_localhost_message : "") + "\n" +
          "Error en: " + funcion + "\n" +
          "Error: " + JSON.stringify(error) + "\n" +
          "Canal: " + this.clientData.stream + "\n" +
          "Token: " + this.token + "\n" +
          "ID Sucursal: " + (this.currentSucursal != '' ? this.currentSucursal : 'Sin selección') + "\n" +
          "ID Categoría musical: " + this.currentCategoria + "\n" +
          "ID Variante de contenido: " + this.currentVariante + "\n" +
          "Zona Horaria: " + zonaHoraria + "\n" +
          "Navegador/OS: " + navigator.userAgent + "\n" +
          "Versión de App: " + this.updateInfo;

        if (this.errorReport.uniqueMessage != uniqueMessage) {
          this.errorReport.errorCount = 0; //resetear el contador de errores
        } else {
          this.errorReport.errorCount++; //incrementar el contador de errores
        }
        this.errorReport.uniqueMessage = uniqueMessage;



        //esperar a que se carguen los datos de message
        await new Promise(r => setTimeout(r, 1000));
        // console.log(message);


        // const messageJson = {
        //   "tipo": "error",
        //   "error": error,
        //   "funcion": funcion,
        //   "canal": this.clientData.stream,
        //   "token": this.token,
        //   "sucursal_id": this.currentSucursal,
        //   "categoria_id": this.currentCategoria,
        //   "variante_id": this.currentVariante,
        //   "zona_horaria": zonaHoraria,
        //   "hora_local": horaServer.data.hora,
        //   "ip": ip.data.ip,
        //   "navegador": navigator.userAgent
        // }
        // console.log(messageJson);
        if (this.errorReport.errorCount < 3) {
          console.log("Enviando reporte de error a Telegram...");
          // console.log(message);

          const dataParameters = {
            chat: '-1001908685177',
            text: message,
          };

          await axios.post('/api/sendmessage', dataParameters)
            .then(response => {
              if (response.data.stat == "error") {
                console.error("Error al enviar mensaje de error a Telegram:", response.data.stat, response.data.stat_message);
              } else {
                console.log("Mensaje de error enviado a Telegram", response);
              }
            })
            .catch(error => {
              console.error("Error al enviar mensaje de error a Telegram:", error.toString());
            });
        } else {
          console.log("No se envió el reporte de error a Telegram porque ya se habían enviado 3 reportes del mismo error");
        }
      } catch (e) {
        console.error("Error en sendErrorReport:", e.toString());
      }
    },
    async sendStats() {
      try {
        //calcular dirección IP
        // const ip = await axios.get("https://api.ipify.org/?format=json");
        const zonaHoraria = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const zonaHorariaG = zonaHoraria.replace(/\//g, "-");
        if (this.horaActual == "") {
          const horaServer = await axios.get('/api/hora/' + zonaHorariaG);
          this.horaActual = horaServer.data.hora ? horaServer.data.hora = horaServer.data.hora : horaServer.data.hora = "No disponible";
        }

        // horaServer.data.hora?horaServer.data.hora = horaServer.data.hora:horaServer.data.hora = "No disponible";
        // console.log("Hora en sendStats: " + horaServer.data.hora);
        // ip.data.ip?"":ip.data.ip = "No disponible";

        const dataParameters = {
          chat: '-715319368',
          text: "| Canal: " + this.clientData.stream +
            " | TOKEN: " + this.token +
            " | ID de sucursal: " + this.currentSucursal +
            " | Categoría musical: " + this.currentCategoria +
            " | Variante de contenido: " + this.currentVariante +
            " | Version de App: " + this.updateInfo +
            " | Navegador/OS: " + navigator.userAgent + "\n" +
            " | Hora local: " + this.horaActual +
            " | Zona horaria: " + zonaHoraria +
            // " | IP: " + ip.data.ip +
            " |"
        };
        // console.log("Parámetros sendStats:");
        // console.log(dataParameters);
        await axios.post("/api/sendmessage", dataParameters)
          .then(data => {
            console.log("%cStats enviados ✅", "color: green; font-weight: bold; background-color: #eee; padding: 5px; border-radius: 5px; font-size: 14px; border: 2px solid green;");
          })
          .catch(error => {
            console.error("Error en sendStats:", error.toString());
          });
      } catch (e) {
        console.error("Error en la función sendStats", e.toString());
        this.sendErrorReport("sendStats", e.toString());
      }
    },
    async getServerTime() {
      try {
        const zonaHoraria = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const zonaHorariaG = zonaHoraria.replace(/\//g, "-");
        // console.log("Zona horaria en getServerTime: " + zonaHorariaG);
        const horaServer = await axios.get('/api/hora/' + zonaHorariaG);
        horaServer.data.hora ? horaServer.data.hora = horaServer.data.hora : horaServer.data.hora = "Hora no disponible";
        //guardarlo en formato h:mm:ss a
        this.horaServer = horaServer.data.hora;

        // console.log("Hora del servidor desde tabReproductor: " + this.horaServer);
        return Promise.resolve(this.horaServer);
      } catch (e) {
        console.error("Error en la función getServerTime", e.toString());
        this.sendErrorReport("getServerTime", e.toString());
      }
    },
    async getTimeFile() {
      try {
        this.playerAudioSource = "";
        this.pause();
        this.horaServer = await this.getServerTime();

        // // guardar la hora menor en una variable
        // if (this.horaServer < this.horaLocal){
        //   this.horaMenor = this.horaServer;
        // } else {
        //   this.horaMenor = this.horaLocal;
        // }
        // console.log("Hora menor: " + this.horaMenor);

        if (this.horaServer == "No disponible" || this.horaServer == "" || this.horaServer == null) {
          console.log("No se pudo obtener la hora del servidor");
          var message = (
            "No se pudo obtener la hora del servidor" +
            "\n Canal: " + this.clientData.stream +
            "\n Hora local: " + horaServer.data.hora +
            "\n Versión del cliente: " + this.updateInfo
          )
          console.error("enviando mensaje de error..." + message);
          this.sendReport(message);
          return;
        }

        //calcular horas y minutos de hora del servidor
        var hora = this.horaServer.split(":")[0];
        var minutos = this.horaServer.split(":")[1];
        var current = new Date(0, 0, 0, hora, minutos, 0, 0);
        console.log("Hora del Date: " + current.toString());
        var fileCalc = (current.getHours() * 60 + current.getMinutes()) % 720;
        var timeFile = 'https://vybroo.net/a/music/Time/' + fileCalc + '.m4a';
        console.log("Solicitando audio de hora para las: " + current.getHours() + ":" + current.getMinutes() + " Archivo:" + timeFile)
        var message = (
          "Solicitud de hora nueva:" +
          "\n Canal: " + this.clientData.stream +
          "\n Hora server/local: " + this.horaServer +
          "\n Versión del cliente: " + this.updateInfo +
          "\n Archivo audio hora: " + timeFile
        );
        console.log(message);
        this.sendReport(message);
        // console.log(timeFile);
        this.playerAudioSource = timeFile;
        this.player.src = this.playerAudioSource;
        return timeFile;
      } catch (e) {
        console.error("Error en la función getTimeFile", e.toString());
        this.sendErrorReport("getTimeFile", e.toString());
      } finally {
        this.play();
      }
    },

    async sendReport(message) {
      try {
        const dataParameters = { text: message };
        console.log("Enviando mensaje: " + message);
        await axios.post("/api/sendmessage", dataParameters)
          .then(data => {
            if (data.data.stat == "ok") {
              console.log(data.data.stat_message);
            } else {
              console.error("error en la función SendReport");
            }
          })
          .catch(error => {
            console.error("error en la función axios.post de SendReport - ", error);
          });
      } catch (e) {
        console.error("Error en la función sendReport", e.toString());
      }
    },
    play() {
      try {
        if (this.playingAudioDemanda) this.killAudioDemanda();
        if (this.muted) {
          this.player.volume = this.unmutedVolume;
          this.muted = false;
          this.emitter.emit("rep-muted", this.muted);
          // if(!this.isPlaying)this.player.play();
        } else {
          if (this.playerAudioSource == "" && this.playerError == false) {
            // this.pause();
            // this.errorNext();
            if (this.token == NaN || isNaN(this.token) || this.token == undefined || this.token == null || this.token == "") {
              console.log("No hay audio source, dando next desde play()...");

              this.token = -1;
              this.errorNext();
            }
            return;

          } else {
            if (this.type != "video") {
              if (this.player && typeof this.player.play === 'function') {
                try {
                  this.player.play().catch(error => {
                    console.log("AUTOPLAY BLOQUEADO POR NAVEGADOR O DISPOSITIVO: " + error + "");
                    console.log(error)
                    // si el error comienza por "NotAllowedError" es porque el autoplay está bloqueado, pausar
                    if (error.toString().startsWith("NotAllowedError")) {
                      console.log("PAUSANDO POR AUTOPLAY BLOQUEADO");
                      this.pause();
                      return;
                    }
                    if (error.toString().startsWith("NotSupportedError: The element has no supported sources")) {
                      console.log("NO SE ENCONTRÓ EL ARCHIVO DE AUDIO, DANDO NEXT ON ERROR...");
                      this.sendErrorReport("play", "ARCHIVO DE AUDIO DAÑADO: " + this.playerAudioSource);
                      this.errorNext();
                      return;
                    }
                    // this.pause();
                  });
                } catch (e) {
                  console.error("ha ocurrido un error en la función play().catch - " + e);
                  // this.presentAlertConfirm("Error en la función play(catch)",e.toString());
                }
              } else {
                console.error('No se puede reproducir el audio: this.player.play no está definido');
                console.log(this.player.play.catch);
                this.presentAlertConfirm("Error al reproducir audio", "Ha ocurrido un error al reproducir el audio, por favor recargue la página. Si el error persiste contacte a su asesor.");
                this.pause();
                return;
              }
            }
          }
        }
        // this.totalTimer = this.millisToMinutesAndSeconds(this.player.duration);
        this.globalPlay = true;
        this.isPlaying = true;
        this.emitter.emit("rep-globalPlay", this.globalPlay);
        this.emitter.emit("rep-isPlaying", this.isPlaying);
      } catch (e) {
        console.error("ha ocurrido un error play - " + e);
        // this.presentAlertConfirm("Error en la función play",e.toString());
        if (e !== "TypeError: this.player.play is not a function")
          this.sendErrorReport("play", e.toString());
        else
          console.error("No enviando mensaje de error");
      }
    },
    vBackPlay() {
      // console.log("===============================================");
      // console.log("vBackPlay()");
      console.log("this.vBackToken: " + this.vBackToken);
      // console.log("this.vBackList[this.vBackToken]: " + this.vBackList[this.vBackToken]);
      // console.log("this.vBackList[this.vBackToken].type: " + this.vBackList[this.vBackToken].type);
      // console.log("this.vBackList[this.vBackToken].file: " + this.vBackList[this.vBackToken].file);
      // console.log("this.playerAudioSource: " + this.playerAudioSource);
      // console.log("===============================================");

      try {
        if (this.playingAudioDemanda) this.killAudioDemanda();
        if (this.muted) {
          this.player.volume = this.unmutedVolume;
          this.muted = false;
          this.emitter.emit("rep-muted", this.muted);
          // if(!this.isPlaying)this.player.play();
        } else {
          if (this.playerAudioSource == "" && this.playerError == false) {
            // this.pause();
            // this.errorNext();
            if (this.vBackToken == NaN || isNaN(this.vBackToken) || this.vBackToken == undefined || this.vBackToken == null || this.vBackToken == "") {
              console.log("No hay audio source, dando next desde vBackPlay()...");
              this.vBackNext();
            }
            return;

          } else {
            if (this.vBackList[this.vBackToken].type != "video") {
                this.playerAudioSource = this.playerAudioSource.startsWith("/a/") ? this.playerAudioSource.replace("/a/", "") : this.playerAudioSource;

                if (this.vBackList[this.vBackToken].type == "song") {
                  //https://pub-3b7d9c13a5754ebeaa0345eb87aa22c1.r2.dev/mp3_320/E/ameritz%20spanish%20karaoke%20-%20frente%20a%20frente%20(in%20the%20style%20of%20enrique%20bunbury)%20%5Bkaraoke%20version%5D.mp3
                  //   music/C/CALONCHO - De día bien.m4a
                  this.playerAudioSource = this.playerAudioSource.startsWith("/a/") ? this.playerAudioSource.replace("/a/", "") : this.playerAudioSource;
                  let split = this.playerAudioSource.split("/");
                  let capitalized = split[split.length - 1].replace(/\w\S*/g, function (txt) { return txt.charAt(0).toLowerCase() + txt.substr(1).toLowerCase(); });
                  let stringFinal = split[split.length - 2] + "/" + capitalized;
                  stringFinal = stringFinal.replace(/ /g, "%20");
                  stringFinal = stringFinal.replace(".m4a", ".mp3");
                  this.playerAudioSource = 'mp3_320/' + stringFinal;
                }


              if (this.player && typeof this.player.play === 'function') {
                try {
                  this.playerAudioSource = 'https://pub-3b7d9c13a5754ebeaa0345eb87aa22c1.r2.dev/' + this.playerAudioSource;
                  // console.log("this.playerAudioSource2: " + this.playerAudioSource);
                  // console.log("===============================================");
                  this.player.src = this.playerAudioSource;
                  this.player.play().catch(error => {
                    console.log("AUTOPLAY BLOQUEADO POR NAVEGADOR O DISPOSITIVO: " + error + "");
                    console.log(error)
                    // si el error comienza por "NotAllowedError" es porque el autoplay está bloqueado, pausar
                    if (error.toString().startsWith("NotAllowedError")) {
                      console.log("PAUSANDO POR AUTOPLAY BLOQUEADO");
                      this.pause();
                      return;
                    }
                    if (error.toString().startsWith("NotSupportedError: The element has no supported sources")) {
                      console.log("NO SE ENCONTRÓ EL ARCHIVO DE AUDIO, DANDO NEXT ON ERROR...");
                      this.sendErrorReport("vBackPlay", "ARCHIVO DE AUDIO DAÑADO: " + this.playerAudioSource);
                      this.vBackNext();
                      return;
                    }
                    // this.pause();
                  });
                } catch (e) {
                  console.error("ha ocurrido un error en la función play().catch - " + e);
                  // this.presentAlertConfirm("Error en la función play(catch)",e.toString());
                }
              } else {
                console.error('No se puede reproducir el audio: this.player.play no está definido');
                console.log(this.player.play.catch);
                this.presentAlertConfirm("Error al reproducir audio", "Ha ocurrido un error al reproducir el audio, por favor recargue la página. Si el error persiste contacte a su asesor.");
                this.pause();
                return;
              }
            }else{
              try {
                  this.playerAudioSource = 'https://pub-3b7d9c13a5754ebeaa0345eb87aa22c1.r2.dev/videos/' + (data.data.file).replace(/ /g, "%20");
                  //this.pause();
                  if (this.videoOn == false) {
                    this.showVideoModal(this.playerAudioSource);
                  } else {
                    this.emitter.emit("rep-VideoSource", this.playerAudioSource);
                  }
                } catch (error) {
                  console.log("Error en nextApiRequest - video - NEXTEMIT", error);
                  this.sendErrorReport("nextApiRequest - video - NEXTEMIT", error);
                }
            }
          }
        }
        // this.totalTimer = this.millisToMinutesAndSeconds(this.player.duration);
        this.globalPlay = true;
        this.isPlaying = true;
        this.emitter.emit("rep-globalPlay", this.globalPlay);
        this.emitter.emit("rep-isPlaying", this.isPlaying);
      } catch (e) {
        console.error("ha ocurrido un error play - " + e);
        // this.presentAlertConfirm("Error en la función play",e.toString());
        if (e !== "TypeError: this.player.play is not a function")
          this.sendErrorReport("play", e.toString());
        else
          console.error("No enviando mensaje de error");
      }
    },
    pause() {
      try {
        if (!this.muted) {
          // this.unmutedVolume = this.player.volume;
          // this.player.volume = 0;
          // this.muted = true;
          this.emitter.emit("rep-muted", this.muted);
          this.player.pause();
        }
        this.isPlaying = false;
        this.emitter.emit("rep-isPlaying", this.isPlaying);
      } catch (e) {
        console.error("ha ocurrido un error pause - " + e);
        // this.presentAlertConfirm("Error en la función pause",e.toString());
        this.sendErrorReport("pause", e.toString());
      }
    },
    listenerOnPause() {
      try {
        this.player.addEventListener("pause", function (e) {
          e.preventDefault();
          if (!this.player.ended) {
            console.log("pausando...");
            this.isPlaying = false;
            this.emitter.emit("rep-isPlaying", this.isPlaying);
          }
        }.bind(this)
        );
      } catch (e) {
        console.error("ha ocurrido un error listenerOnPause - " + e);
        // this.presentAlertConfirm("Error en la función listenerOnPause",e.toString());
        this.sendErrorReport("listenerOnPause", e.toString());
      }
    },
    listenerOnPlay() {
      try {
        this.player.addEventListener("play", function () {
          console.log("reproduciendo...");
          this.isPlaying = true;
          this.emitter.emit("rep-isPlaying", this.isPlaying);
        }.bind(this)
        );
      } catch (e) {
        console.error("ha ocurrido un error listenerOnPlay - " + e);
        // this.presentAlertConfirm("Error en la función listenerOnPlay",e.toString());
        this.sendErrorReport("listenerOnPlay", e.toString());
      }
    },
    millisToMinutesAndSeconds(millis) {
      try {
        // console.log(millis);
        millis = millis.toString().replace(",", ".");
        millis = parseInt(millis);
        var minutes = Math.floor(millis / 60000);
        var seconds = ((millis % 60000) / 1000).toFixed(0);
        return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
      } catch (e) {
        console.error("ha ocurrido un error millisToMinutesAndSeconds - " + e);
        // this.presentAlertConfirm("Error en la función millisToMinutesAndSeconds",e.toString());
        this.sendErrorReport("millisToMinutesAndSeconds", e.toString());
      }
    },
    listenerOnEnded() {
      // console.log("listenerOnEnded llamado");
      if (!this.nextRunning) {
        try {
          this.player.addEventListener("ended", function () {
            if (this.isPlaying == true)
              // console.log("next triggered from function listenerOnEnded()");
            this.next();
          }.bind(this)
          );
        } catch (e) {
          console.error("ha ocurrido un error listenerOnEnded - " + e);
          // this.presentAlertConfirm("Error en la función listenerOnEnded",e.toString());
          this.sendErrorReport("listenerOnEnded", e.toString());
        }
      }
    },
    errorNext() {
      try {
        if (this.playerError == false && this.isPlaying == true && this.globalPlay == true) {
          this.playerError = true;
          this.title = "Cargando...";
          this.artist = "Vybroo";
          this.audio_id = '0';
          this.emitter.emit("rep-title", this.title);
          this.emitter.emit("rep-artist", this.artist);
          setTimeout(() => {
            console.log("ERROR AL CARGAR LA CANCIÓN, SALTANDO DESDE errorNext()...");
            // console.log("cambiando...");
            // this.killAudio();
            this.playerError = false;
            this.playErrorCount++;
            console.log("playErrorCount: " + this.playErrorCount);
            if (this.playErrorCount >= 3) {
              this.artist = "Error al cargar la canción";
              this.title = "Error";
              this.audio_id = '0';
              this.emitter.emit("rep-title", this.title);
              this.emitter.emit("rep-artist", this.artist);
              // this.pause();
              // this.presentAlertConfirm("Error al cargar la canción", "Ha ocurrido un error al cargar la canción, por favor recargue la página. Si el error persiste contacte a su asesor.");
              this.playErrorCount = 0;
              this.vBackNext();
              this.sendErrorReport("ATENCIÓN", "SE HAN INTENTADO REPRODUCIR 3 CANCIONES CONSECUTIVAS CON ERROR ENTRAMOS EN vBack");
              return;
              // ACTIVATE VBACK TO BASE FUNCTION HERE IF ERROR PERSISTS AFTER 10 SONGS IN A ROW WITH ERROR

            } else {
              this.next();
              return;
            }
            this.next();

          }, 3000);
        } else {
          console.log("No entró al listener de error: " + this.isPlaying + " " + this.globalPlay);
          this.next();
        }
      } catch (e) {
        console.error("ha ocurrido un error errorNext - " + e);
        // this.presentAlertConfirm("Error en la función errorNext - ",e.toString());
        this.sendErrorReport("errorNext", e.toString());
        this.next();
      }

    },
    listenerOnError() {
      try {
        this.player.addEventListener("error", function (e) {
          if (this.playerError == false && this.isPlaying == true && this.globalPlay == true) {
            //enviar reporte de error con el mensaje de error
            // this.sendErrorReport("listenerOnError", e.error?e.error:e);
            if (this.token == NaN || this.token == undefined || this.token == null || this.token == "") {

              console.log("token no definido, no se envía reporte de error, token: " + this.token);
            } else {
              // si los ultimos 5 tokens consecutivos dan error y la lastCategorieError es la misma, enviar error de que la categoría está vacía y regresar a la base

              if (this.lastCategoriaError == this.currentCategoria && this.tokenErrorCount >= 5 && (this.currentCategoria != "0" || this.currentCategoria != 0)) {
                console.error("enviando reporte de error, token: " + this.token);
                this.sendErrorReport("listenerOnError", "CATEGORÍA MUSICAL VACÍA: " + this.currentCategoria);
                this.presentAlertConfirm("Aviso", "Se regresó a la categoría musical base debido a un error en la categoría musical seleccionada, si el error persiste, por favor de reportarlo a su asesor. Gracias.");
                this.tokenErrorCount = 0;
                this.returnToBase();
                return;
              }

              if (this.lastTokenError == this.token - 1 && this.lastCategoriaError == this.currentCategoria) {
                this.tokenErrorCount++;
                console.error("tokenErrorCount: " + this.tokenErrorCount);
              } else {
                this.tokenErrorCount = 0;
                console.error("tokenErrorCount: " + this.tokenErrorCount);
                console.error("No es el mismo token o no es la misma categoría musical, enviando reporte de error, token: " + this.token);
                if (!this.token || this.token == NaN || this.token == undefined || this.token == null || this.token == "") {
                  console.error("token no definido, no se envía reporte de error, token: " + this.token);
                } else {
                  // console.error("enviando reporte de error, token: " + this.token);
                  // this.sendErrorReport("listenerOnError", "Error al cargar la canción, token: " + this.token + ", categoría: " + this.currentCategoria);
                }
              }
              this.lastCategoriaError = this.currentCategoria;
              this.lastTokenError = this.token;
            }
            this.errorNext();
          }
        }.bind(this)
        );
      } catch (e) {
        console.error("ha ocurrido un error listenerOnError - " + e);
        // this.presentAlertConfirm("Error en la función listenerOnError",e.toString());
        this.sendErrorReport("listenerOnError", e.toString());
      }
    },
    listenerOnEndedAudioDemanda() {
      try {
        this.playerAudioDemanda.addEventListener("ended", function () {
          console.log("audio terminó de sonar");
          this.killAudioDemanda();
          this.play();
        }.bind(this)
        );
      } catch (e) {
        console.error("ha ocurrido un error listenerOnEndedAudioDemanda - " + e);
        // this.presentAlertConfirm("Error en la función listenerOnEndedAudioDemanda",e.toString());
        this.sendErrorReport("listenerOnEndedAudioDemanda", e.toString());
      }
    },
    killAudioDemanda() {
      try {
        Storage.remove({ key: 'audioDemanda' });
        this.playerAudioDemanda.pause();
        this.playerAudioDemandaSource = '';
        this.playingAudioDemanda = false;
        // this.playerAudioDemanda.stop();
      } catch (e) {
        console.error("ha ocurrido un error killAudioDemanda - " + e);
        // this.presentAlertConfirm("Error en la función killAudioDemanda",e.toString());
        this.sendErrorReport("killAudioDemanda", e.toString());
      }
    },
    killAudio() {
      try {
        this.player.pause();
        this.playerAudioSource = '';
        this.playingAudio = false;
      } catch (e) {
        console.error("ha ocurrido un error killAudio - " + e);
        // this.presentAlertConfirm("Error en la función killAudio",e.toString());
        this.sendErrorReport("killAudio", e.toString());
      }
    },
    async next() {
      // console.log("===:next: llamado");
      if (!this.nextRunning) {
        this.nextRunning = true;
        try {
          if (this.playerError == false) {

            this.checkOnline();
            if (this.token !== -1 && this.token && this.token == this.previousToken) {
              this.token += 1;
            }

            await this.nextApiRequest();
          } else {
            this.token = -1;
          }
        } catch (e) {
          console.error("ha ocurrido un error next - " + e);
          // this.presentAlertConfirm("Error en la función next",e.toString());
          this.sendErrorReport("next", e.toString());
        }
        this.nextRunning = false;
      }
    },
    async vBackNext() {
      this.vBackStatus = true;
      this.vBackToken++;
        if (this.vBackToken >= this.vBackList.length) {
          this.vBackToken = 0;
        }
      // console.log("===:vBackNext: llamado");
      if (this.vBackList.length > 0) {
        console.log("Reproduciendo siguiente canción en vBackList...");

        if (this.vBackList[this.vBackToken].type == 'spot') {
            let spotFiltrado = false;
            while (spotFiltrado == false) {
              //if (this.vBackList[this.vBackToken].cat_filter == "Promociones") {
              if (this.vBackList[this.vBackToken].cat_filter == "Promociones" ||
                  this.vBackList[this.vBackToken].cat_filter == "Productos" ||
                  this.vBackList[this.vBackToken].cat_filter == "") {
                this.vBackToken++;
              } else {
                spotFiltrado = true;
              }
            }
        }

        this.title = this.vBackList[this.vBackToken].title;
        this.artist = this.vBackList[this.vBackToken].artist;
        this.audio_id = this.vBackList[this.vBackToken].id;
        this.emitter.emit("rep-title", this.title);
        this.emitter.emit("rep-artist", this.artist);
        this.playerAudioSource = this.vBackList[this.vBackToken].file;
        this.player.src = this.playerAudioSource;
        this.vBackPlay();

      }else{
        console.log("Llenando la vBackList...");
        try {
          await axios.get('/transmito/vbk_get_sequence/?stream='+this.clientData.stream)
            .then(data => {
              // console.log(data);
              this.vBackList = data.data;
              this.vBackNext();
            })
            .catch(error => {
              console.error("Error en vBackNext - axios.get", error);
              this.sendErrorReport("vBackNext - axios.get", error);
            });

        }catch (e) {
          console.error("Error en vBackNext", e);
          this.sendErrorReport("vBackNext", e);
        }
      }
    },
    async nextApiRequest() {
      // console.log("===:nextApiRequest: llamado");
      try {
        this.getApiRequestData();
        const dataParameters = { stream: this.clientName.toLowerCase(), token: this.token, cid: this.currentSucursal, music_variant: this.currentCategoria, variant: this.currentVariante, video: this.clientData.video_enabled };
        // console.log(dataParameters);
        await axios.post("https://vybroo.net/api", dataParameters)
          .then(data => {
            // console.log(data);
            if (data.data.code == 503 || data.data.error || this.token == NaN || isNaN(this.token) || this.token == undefined || this.token == null || data.data.code == 404) {
              // this.token = -1;
              setTimeout(() => {
                console.error("Saltando por error en servidor, intentando nuevamente...");
                this.title = "Error, reintentando...";
                this.artist = "Vybroo";
                this.audio_id = '0';
                this.emitter.emit("rep-title", this.title);
                this.emitter.emit("rep-artist", this.artist);
                if (this.nextApiErrors >= 3) {
                  this.vBackNext();
                }else{
                  this.nextApiErrors++;
                  this.nextApiRequest();
                }
                return;
              }, 2000);
            } else {
              this.vBackStatus = false;
              this.nextApiErrors = 0;
              this.killAudio();
              this.token = Number(data.data.token);
              console.log("TOKEN actual: " + this.token + " - TOKEN previo: " + this.previousToken);
              this.previousToken = this.token;
              this.type = data.data.type;
              // console.log("TYPE: " + this.type + " desde NEXT" );
              this.title = data.data.title ? data.data.title : 'Cargando...';
              this.bkTitle = data.data.title ? data.data.title : 'Cargando...';
              this.audio_id = data.data.id ? data.data.id : '0';
              if (this.sonara) {
                this.artist = data.data.artist ? data.data.artist : 'Sonara';
                this.bkArtist = data.data.artist ? data.data.artist : 'Sonara';
              } else {
                this.artist = data.data.artist ? data.data.artist : 'Vybroo';
                this.bkArtist = data.data.artist ? data.data.artist : 'Vybroo';
              }
              // console.info("Llamando emits desde nextApiRequestData()...");
              this.emitter.emit("rep-token", this.token);
              this.emitter.emit("rep-title", this.title);
              this.emitter.emit("rep-artist", this.artist);
              this.emitter.emit("rep-type", this.type);
              // console.info("Emits llamados desde nextApiRequestData()...");

              if (data.data.type != 'video') {

                // this.artist = this.token;
                if (data.data.type == 'time') {
                  this.playerAudioSource = this.getTimeFile();
                  this.title = 'Hora';
                  this.audio_id = '0';
                  if (this.sonara) {
                    this.artist = 'Sonara';
                  } else {
                    this.artist = 'Vybroo';
                  }
                }

                if (data.data.type == 'song') {
                  console.log("Reproduciendo canción...");
                  if (data.data.id) { this.getHighestSongQuality(data.data.id, data.data.file) }
                }
                if (data.data.type == 'spot') {
                  console.log("Reproduciendo spot...");
                  this.playerAudioSource = 'https://vybroo.net' + (data.data.file).replace(/ /g, "%20");
                  this.player.src = this.playerAudioSource;
                }
                this.emitter.emit("rep-title", this.title);
                this.emitter.emit("rep-artist", this.artist);
                // console.log(this.playerAudioSource);
                this.player.preload = "auto";
                this.liked = 0;
                this.emitter.emit("rep-liked", this.liked);
                if (this.saveMusicVariant.nextSwitch === true && this.currentCategoria !== 0) {
                  this.returnToBase();
                  console.log("nextSwitch activado, saltando a la variante base...");
                  this.token = -1;
                  this.nextApiRequest();
                  return;
                }
                if (data.data.type == 'song') return;
                this.play();
              } else { // ES VIDEO
                try {
                  this.playerAudioSource = 'https://vybroo.net/videos/' + (data.data.file).replace(/ /g, "%20");
                  //this.pause();
                  if (this.videoOn == false) {
                    this.showVideoModal(this.playerAudioSource);
                  } else {
                    this.emitter.emit("rep-VideoSource", this.playerAudioSource);
                  }
                } catch (error) {
                  console.log("Error en nextApiRequest - video - NEXTEMIT", error);
                  this.sendErrorReport("nextApiRequest - video - NEXTEMIT", error);
                }

              }

              // if(this.muted) this.player.volume = 0;
            }
          })
          .catch(error => {
            if (this.enLinea == 2) {
              console.log("Error de conexión, reintentando... (nextApiRequest)", error);
              this.sendErrorReport("nextApiRequest (error de conexión)", error);
              setTimeout(() => {
                this.next();
              }, 2000);
            }else{
              this.vBackNext();
              console.error("Error en nextApiRequest - axios.post", error);
              this.sendErrorReport("nextApiRequest - axios.post", error);
            }
            // this.presentAlertConfirm("Error en loadApi de la función nextApiRequest",error);
          });
      } catch (e) {
        console.error("ha ocurrido un error en la función nextApiRequest - " + e);
        // this.presentAlertConfirm("ha ocurrido un error en la función nextApiRequest",e.toString());
        this.sendErrorReport("nextApiRequest", e.toString());
      }
    },


    async getClientData() {
      try {
        this.checkOnline();
        const ret = await Storage.get({ key: "clientData" });
        if (ret.value) {
          this.clientData = JSON.parse(ret.value);
          // this.sendReport("Inicio de sesión nuevo: \n Canal: " + this.clientData.stream);
          this.emitter.emit("g-data", this.clientData);
          //imagen reproductor
          this.clientImageSrc = 'https://pub-3b7d9c13a5754ebeaa0345eb87aa22c1.r2.dev/images/' + this.clientData.image;
          // console.log(this.clientImageSrc);
          //Client data en get:
          this.clientName = this.clientData.stream;


          if (this.clientData.permissions) {
            this.emitter.emit("permissions", this.clientData.permissions);
            this.listenedChanges.permissions = this.clientData.permissions;
          }

          if (this.clientData.sonara) {
            this.emitter.emit("sonara", this.clientData.sonara);
            this.listenedChanges.sonara = this.clientData.sonara;
            console.log("Sonará: " + this.clientData.sonara);
          }

          if (this.clientData.default_quality) {
            this.clientData.default_quality == "32" ? this.sound.quality = "m4a_32" : "";
            this.clientData.default_quality == "128" ? this.sound.quality = "mp3_128" : this.sound.quality = "m4a_32";
            this.clientData.default_quality == "320" ? this.sound.quality = "mp3_320" : this.sound.quality = "m4a_32";
          } else {
            this.sound.quality = "m4a_32";
          }

          if (this.clientData.quality_controls) {
            this.sound.controls = this.clientData.quality_controls;
            this.emitter.emit("quality-controls", this.sound.controls);
          } else {
            this.sound.controls = false;
          }

          // console.log(this.clientData);

          // console.log(this.clientName);
          //color de fondo
          this.clientColor = "#" + this.clientData.color;
          // console.log(this.clientData.color);

          //GUARDAR PREFERENCIAS EN CATEGORÍA MUSICAL
          this.saveMusicVariant.enabled = this.clientData.save_m_variant;
          // console.log("SAVE MUSIC VARIANT: " + this.clientData.save_m_variant);
          if (this.saveMusicVariant.enabled == false) {
            // console.log("Save Music Variant disabled - save_m_variant: " + this.saveMusicVariant.enabled);
            Storage.remove({ key: 'categoriaSeleccionada' });
          }

          // CAPTAR SUCURSAL EN PARÁMETROS DE URL
          this.urlParams.sucursalMatch = false;
          this.clientData.identifiers.forEach(element => {
            if (element.id == this.urlParams.sucursal) {
              console.log("La sucursal del URL existe en la lista de sucursales del canal: " + this.urlParams.sucursal + " = " + element.id);
              // this.presentAlertConfirm("Sucursal activada:",element.identifier);
              this.urlParams.sucursalMatch = true;
              this.urlParams.sucursalMatchName = element.identifier;
              this.currentSucursal = Number(this.urlParams.sucursal);
              this.emitter.emit("rep-currentSucursal", this.currentSucursal);
              Storage.remove({ key: 'sucursalSeleccionada' });
              Storage.set({
                key: "sucursalSeleccionada",
                value: JSON.stringify(element.identifier),
              })
                .then
              console.log("STORAGE: sucursal guardada: " + element.identifier);
            }
          });
          if (this.urlParams.sucursalMatch == false && Number(this.urlParams.sucursal) >= 0 && this.urlParams.sucursal) {
            this.presentAlertConfirm("Atención", "El ID de sucursal ingresado (" + this.urlParams.sucursal + ") en el URL no coincide con ninguna sucursal registrada, revise si el URL se pegó completo.");
          }

          // CAPTAR SUCURSALNAME EN PARÁMETROS DE URL
          this.urlParams.sucursalNameMatch = false;
          this.clientData.identifiers.forEach(element => {
            if (element.identifier == this.urlParams.sucursalName) {
              console.log("La sucursalName del URL existe en la lista de sucursales del canal: " + this.urlParams.sucursalName + " = " + element.identifier);
              // this.presentAlertConfirm("Sucursal activada:",element.identifier);
              this.urlParams.sucursalNameMatch = true;
              this.urlParams.sucursalNameMatchName = element.identifier;
              this.currentSucursal = element.id;
              this.emitter.emit("rep-currentSucursal", element.id);
              Storage.remove({ key: 'sucursalSeleccionada' });
              Storage.set({
                key: "sucursalSeleccionada",
                value: JSON.stringify(element.identifier),
              })
                .then
              console.log("STORAGE: sucursalName guardada: " + element.identifier);
            }
          });
          if (this.urlParams.sucursalNameMatch == false && this.urlParams.sucursalName) {
            this.presentAlertConfirm("Atención", "El ID de sucursal ingresado (" + this.urlParams.sucursalName + ") en el URL no coincide con ninguna sucursal registrada, revise si el URL se pegó completo.");
          }

          // CAPTAR CATEGORÍA MUSICAL EN PARÁMETROS DE URL
          this.urlParams.categoriaMatch = false;
          this.clientData.music_variants.forEach(element => {
            if (element.id == this.urlParams.categoria) {
              console.log("La categoría del URL existe en la lista de sucursales del canal: " + this.urlParams.categoria + " = " + element.id);
              // this.presentAlertConfirm("Categoría musical activada:",element.name);
              this.urlParams.categoriaMatch = true;
              this.urlParams.categoriaMatchName = element.name;
              this.currentCategoria = Number(this.urlParams.categoria);
              this.currentCategoriaAux = Number(this.urlParams.categoria);
              this.emitter.emit("rep-currentCategoria", this.currentCategoria);
              Storage.remove({ key: 'categoriaSeleccionada' });
              Storage.set({
                key: "categoriaSeleccionada",
                value: JSON.stringify(this.urlParams.categoria),
              })
                .then
              console.log("STORAGE: variante musical guardada: " + this.urlParams.categoria);
            }
          });
          if (this.urlParams.categoriaMatch == false && Number(this.urlParams.categoria) >= 0 && this.urlParams.categoria) {
            this.presentAlertConfirm("Atención", "El ID de categoría musical ingresado (" + this.urlParams.categoria + ") en el URL no coincide con ninguna categoría registrada, revise si el URL se pegó completo.");
          }

          // CAPTAR VARIANTE DE CONTENIDO EN PARÁMETROS DE URL
          this.urlParams.varianteMatch = false;
          // console.log(this.clientData.variants);
          this.clientData.variants.forEach(element => {
            if (element.id == this.urlParams.variante) {
              console.log("La variante del URL existe en la lista de sucursales del canal: " + this.urlParams.variante + " = " + element.id);
              // this.presentAlertConfirm("Variante de contenido activada:",element.name);
              this.urlParams.varianteMatch = true;
              this.urlParams.varianteMatchName = element.name;
              this.currentVariante = Number(this.urlParams.variante);
              this.currentVarianteAux = Number(this.urlParams.variante);
              this.emitter.emit("rep-currentVariante", this.currentVariante);
              Storage.remove({ key: 'varianteSeleccionada' });
              Storage.set({
                key: "varianteSeleccionada",
                value: JSON.stringify(this.urlParams.variante),
              })
                .then
              console.log("STORAGE: variante de contenido guardada: " + this.urlParams.variante);
            }
          });
          if (this.urlParams.varianteMatch == false && Number(this.urlParams.variante) >= 0 && this.urlParams.variante) {
            this.presentAlertConfirm("Atención", "El ID de variante de contenido ingresado (" + this.urlParams.variante + ") en el URL no coincide con ninguna variante registrada, revise si el URL se pegó completo.");
          }

          if (this.urlParams.sucursalMatch || this.urlParams.sucursalNameMatch || this.urlParams.categoriaMatch || this.urlParams.varianteMatch) {
            var sucursalInfo = this.urlParams.sucursalMatch ? "<strong>ID de sucursal: </strong>" + this.urlParams.sucursalMatchName + ".<br>" : "";
            var sucursalNameInfo = this.urlParams.sucursalNameMatch ? "<strong>ID de sucursal: </strong>" + this.urlParams.sucursalNameMatchName + ".<br>" : "";
            var categoriaInfo = this.urlParams.categoriaMatch ? "<strong>Categoría musical: </strong>" + this.urlParams.categoriaMatchName + ".<br>" : "";
            var varianteInfo = this.urlParams.varianteMatch ? "<strong>Variante de contenido: </strong>" + this.urlParams.varianteMatchName + ".<br>" : "";
            // this.presentAlertConfirm("Se cargó la siguiente configuración:", sucursalInfo + sucursalNameInfo + categoriaInfo + varianteInfo)
          }


          this.loadApi(this.clientData.stream);
        } else {
          Storage.clear();
          this.$router.replace("/login/loginUser");
        }
      } catch (e) {
        console.error("ha ocurrido un error getClientData - " + e);
        // this.presentAlertConfirm("Error en la función getClientData",e.toString());
        this.sendErrorReport("getClientData", e.toString());
      }
    },
    async intro_expired() {
      try {
        const ret = await Storage.get({ key: "intro_expires" });
        if (ret.value) {

          const intro_expires = this.isJson(ret.value);
          const introDate = new Date(intro_expires);
          const currentDate = new Date();

          //igualar formatos de introDate y currentDate para comparar
          introDate.setHours(introDate.getHours(), introDate.getMinutes(), introDate.getSeconds(), introDate.getMilliseconds());
          currentDate.setHours(currentDate.getHours(), currentDate.getMinutes(), currentDate.getSeconds(), currentDate.getMilliseconds());
          // console.log("introDate: " + introDate.toLocaleString());
          // console.log("currentDate: " + currentDate.toLocaleString());
          let diff = Math.round((introDate - currentDate) / 1000 / 60);
          //quitar el signo negativo
          if (diff < 0) {
            diff = diff * -1;
          }

          if (introDate < currentDate) {
            // console.log("intro_expires es menor a currentDate, se regresará true");
            // console.log("el intro lleva expirado " + diff + " minutos");
            Storage.remove({ key: 'intro_expires' });
            let new_intro_expires = new Date();
            //sumar 1 minuto
            // new_intro_expires.setMinutes(new_intro_expires.getMinutes() + 1);
            //sumar 12 horas
            new_intro_expires.setHours(new_intro_expires.getHours() + 12);
            //sumar 1 día
            // new_intro_expires.setDate(new_intro_expires.getDate() + 1);
            // console.log("El intro expirará a las: " + new_intro_expires.toLocaleString());
            Storage.remove({ key: 'intro_expires' });
            Storage.set({
              key: "intro_expires",
              value: JSON.stringify(new_intro_expires),
            })
              .then
            return true;
          } else {
            // console.log("intro_expires es mayor a currentDate, se regresará false");
            // console.log("El intro expirará en " + diff + " minutos.");
            return false;
          };
        } else {
          //guardar la fecha de vencimiento de la intro un dia después de la fecha actual y regresar false
          console.log("intro_expires no existe en storage, se creará con fecha de vencimiento de un día")
          let new_intro_expires = new Date();
          //sumar 1 minuto
          new_intro_expires.setMinutes(new_intro_expires.getMinutes() + 1);
          //sumar 12 horas
          // new_intro_expires.setHours(new_intro_expires.getHours() + 12);
          //sumar 1 día
          // new_intro_expires.setDate(new_intro_expires.getDate() + 1);
          console.log("new_intro_expires: " + new_intro_expires.toLocaleString());
          Storage.remove({ key: 'intro_expires' });
          Storage.set({
            key: "intro_expires",
            value: JSON.stringify(new_intro_expires),
          })
            .then
          return true;
        }
      } catch (e) {
        console.error("ha ocurrido un error intro_expired - " + e);
        // this.presentAlertConfirm("Error en la función getIntroPlayedExpirationDate",e.toString());
        this.sendErrorReport("intro_expired", e.toString());
      }
    },
    async getSucursal() {
      try {
        const retSucursal = await Storage.get({ key: "sucursalSeleccionada" });
        if (retSucursal.value) {
          this.currentSucursal = this.isJson(retSucursal.value);
          this.emitter.emit("rep-currentSucursal", this.currentSucursal);
          // console.log("Sucursal actual: " + this.currentSucursal);
        } else {
          if (!this.urlParams.sucursalMatch && !this.urlParams.sucursalNameMatch) {
            // console.log("no hay sucursal seleccionada");
            this.currentSucursal = "";
          }
          this.emitter.emit("rep-currentSucursal", this.currentSucursal);
        }
      } catch (e) {
        console.error("ha ocurrido un error getSucursal - " + e);
        // this.presentAlertConfirm("Error en la función getSucursal",e.toString());
        this.sendErrorReport("getSucursal", e.toString());
      }
    },
    msToTime(duration) {
      try { return moment.utc(duration).format("HH:mm:ss"); }
      catch (e) {
        console.error("ha ocurrido un error msToTime - " + e);
        // this.presentAlertConfirm("Error en la función msToTime",e.toString());
        this.sendErrorReport("msToTime", e.toString());
      }
    },
    async getCategoria() {
      try {
        const retCategoria = await Storage.get({ key: "categoriaSeleccionada" });
        if (retCategoria.value) {
          this.currentCategoria = this.isJson(retCategoria.value);
          this.emitter.emit("rep-currentCategoria", this.currentCategoria);
          // console.log("Categoría actual: " + this.currentCategoria);
          if (retCategoria.value != this.currentCategoriaAux && this.currentCategoriaAux != null) {
            console.log("se cambió la categoría de " + this.currentCategoriaAux + " a " + this.currentCategoria);
            if (this.clientData.save_m_variant === false && this.clientData.smv_time > 0) {
              console.log("saveMusicVariant está desactivado");
              if (this.currentCategoria === 0) {
                //reiniciar valores de saveMusicVariant
                console.log("reiniciando valores de saveMusicVariant")
                this.saveMusicVariant.enabled = false;
                this.saveMusicVariant.time = 0;
                this.saveMusicVariant.timeString = "00:00:00";
                Storage.remove({ key: 'saveMusicVariantTime' });

              } else {
                //registrar en storage el valor de smv_time mas la fecha actual para que se guarde la variante musical
                console.log("Iniciando temporizador de saveMusicVariant");
                var date = new Date();
                console.log("fecha actual:" + date);
                var minutes = date.getMinutes() + this.clientData.smv_time;
                date.setMinutes(minutes);
                console.log("fecha actual + smv_time:" + date);
                Storage.set({
                  key: "saveMusicVariantTime",
                  value: date.toString(),
                })
                console.log("Storage saveMusicVariantTime guardado:");
                var storageDate = await Storage.get({ key: "saveMusicVariantTime" });
                console.log(storageDate.value);
              }
            }

            // this.presentAlertConfirm("Atención","Se cambió de varante musical - " + this.currentCategoria);
            this.globalPlay = true;
            // this.isPlaying = true;
            if (this.playerError == false) {
              console.log("ejecutando next() desde getCategoria()");
              this.token = -1;
              this.next();
              this.play();
            }
          }
          // Revisar si existe en el storage saveMusicVariantTime y si es mayor a la fecha actual, si es así, se guarda la variante musical, si no se borra el storage y se reinician los valores de saveMusicVariant y la categoría se cambia a 0
          var storageDate = await Storage.get({ key: "saveMusicVariantTime" });
          if (storageDate.value) {
            // revisar la fecha del storage y la diferencia respecto a la fecha actual
            var date = new Date();
            var storageDate = new Date(storageDate.value);
            var diff = storageDate.getTime() - date.getTime();
            // console.log("Tiempo restante: " + this.msToTime(diff));
            if (diff > 0) {
              // guardar la variante musical
              // console.log("guardando variante musical");
              this.saveMusicVariant.enabled = true;
              this.saveMusicVariant.time = diff;
              this.saveMusicVariant.timeString = this.msToTime(diff);
              this.saveMusicVariant.nextSwitch = false;

            } else {
              if (this.saveMusicVariant.nextSwitch === false) {
                console.log("Se regresará a base al terminar la canción actual");
                Toast.show({ text: 'AVISO: Se regresará a base al terminar la canción actual.', duration: 'long' });
                this.saveMusicVariant.nextSwitch = true;
              }


            }
          }


          this.currentCategoriaAux = retCategoria.value;
        } else {
          if (!this.urlParams.categoriaMatch) {
            // console.log("no hay categoría seleccionada");
            this.currentCategoria = 0;
            this.currentCategoriaAux = 0;
          }
          this.emitter.emit("rep-currentCategoria", this.currentCategoria);
          this.emitter.emit("rep-currentCategoriaAux", this.currentCategoriaAux);
        }

        //TIMER DE !SAVE MUSIC VARIANT
        if (!this.saveMusicVariant.enabled && !this.saveMusicVariant.timerRunning) {
          console.log("Categoría seleccionada: " + this.currentCategoria)
          this.saveMusicVariant.timerRunning = true;
        }
      } catch (e) {
        console.error("ha ocurrido un error getCategoria - " + e);
        // this.presentAlertConfirm("Error en la función getCategoria",e.toString());
        this.sendErrorReport("getCategoria", e.toString());
      }
    },
    returnToBase() {
      try {
        console.log("Regresando a la variante base - INICIO")
        // borrar el storage y reiniciar los valores de saveMusicVariant
        this.saveMusicVariant.nextSwitch = false;
        console.log("borrando storage y reiniciando valores de saveMusicVariant");
        Storage.remove({ key: 'saveMusicVariantTime' });
        this.saveMusicVariant.enabled = false;
        this.saveMusicVariant.time = 0;
        this.saveMusicVariant.timeString = "00:00:00";
        this.saveMusicVariant.timerRunning = false;
        this.currentCategoria = "0";
        this.currentCategoriaAux = "0";

        Storage.set({
          key: "categoriaSeleccionada",
          value: JSON.stringify(this.currentCategoria),
        });

        this.emitter.emit("cambio-categoria", this.currentCategoria);
        this.emitter.emit("rep-currentCategoria", this.currentCategoria);
        this.emitter.emit("rep-currentCategoriaAux", this.currentCategoriaAux);
        this.globalPlay = true;
        this.isPlaying = true;
        console.log("Regresando a la variante base - FIN")
        this.next();
      } catch (e) {
        console.error("ha ocurrido un error en returnToBase - " + e);
        // this.presentAlertConfirm("Error en la función returnToBase",e.toString());
        this.sendErrorReport("returnToBase", e.toString());
      }
    },
    async getAudioDemanda() {
      try {
        const retAudio = await Storage.get({ key: "audioDemanda" });
        if (retAudio.value && this.globalPlay) {
          this.killAudioDemanda();
          // console.log(JSON.parse(retAudio.value).file);
          this.playerAudioDemandaSource = 'https://vybroo.net/a/spots/' + (JSON.parse(retAudio.value).file).replace(/ /g, "%20");
          console.log(this.playerAudioDemandaSource);
          this.playerAudioDemanda.src = this.playerAudioDemandaSource;
          //pausar reproductor normal
          this.pause();
          Storage.remove({ key: 'audioDemanda' });
          this.playerAudioDemanda.play();
          // this.presentAlertConfirm("Reproduciendo audio", isJson(retAudio.value.name));
          this.playingAudioDemanda = true;
          this.listenerOnEndedAudioDemanda();
        } else {
          // console.log("----- AUDIOS ERROR ----");
          // if(!retAudio.value) console.log("no hay audios a demanda en storage")
          // if(this.playingAudioDemanda) console.log("playingAudioDemanda = true")
          // if(!this.globalPlay) console.log("globalPlay = false")
          // console.log("----- AUDIOS ERROR ----");
          // this.playingAudioDemanda = false;

          // console.log(this.playingAudioDemanda);

        }
      } catch (e) {
        console.log("error en la función getAudioDemanda - " + e);
        // this.presentAlertConfirm("Error en la función getAudioDemanda",e.toString());
        this.sendErrorReport("getAudioDemanda", e.toString());
        // this.playingAudioDemanda = false;
      }
    },
    async getVariante() {
      try {
        const retVariante = await Storage.get({ key: "varianteSeleccionada" });
        if (retVariante.value) {
          this.currentVariante = this.isJson(retVariante.value);
          this.emitter.emit("rep-currentVariante", this.currentVariante);
          //guardar también el nombre de la variante
          this.clientData.variants.forEach(element => {
            if (element.id == this.currentVariante) {
              this.currentVarianteName = element.name;
              this.emitter.emit("rep-currentVarianteName", this.currentVarianteName);
              // console.log("Variante actual: " + this.currentVarianteName);
            }
          });
          // console.log("Categoría actual: " + this.currentCategoria);
          if (retVariante.value != this.currentVarianteAux && this.currentVarianteAux != null) {
            console.log("se cambió la varante de " + this.currentVarianteAux + " a " + this.currentVariante);
            // this.presentAlertConfirm("Atención","Se cambió de varante de contenido - " + this.currentVariante);
            this.globalPlay = true;
            // this.isPlaying = true;
            if (this.playerError == false) {
              console.log("ejecutando next() desde getVariante()");

              this.next();
            }
            this.play();
          }
          this.currentVarianteAux = retVariante.value;
        } else {
          if (!this.urlParams.varianteMatch) {
            this.currentVariante = 0;
            this.currentVarianteAux = 0;
          }
          this.emitter.emit("rep-currentVariante", this.currentVariante);
          this.emitter.emit("rep-currentVarianteAux", this.currentVarianteAux);
        }
      } catch (e) {
        console.log("error en la función getVariante - " + e);
        // this.presentAlertConfirm("Error en la función getVariante",e.toString());
        this.sendErrorReport("getVariante", e.toString());
      }
    },
    async getApiRequestData() {
      try {
        await this.getSucursal();
        await this.getCategoria();
        await this.getAudioDemanda();
        await this.getVariante();
        // this.currentSucursal!=""?console.log("Sucursal actual: " + this.currentSucursal):console.log("no hay sucursal seleccionada");
        // this.currentCategoria!=0?console.log("Categoría actual: " + this.currentCategoria):console.log("no hay categoría seleccionada");
        // this.playerAudioDemandaSource!=''?console.log("Audio a demanda actual: " + this.playerAudioDemandaSource):console.log("no hay audio a demanda sonando");
      } catch (e) {
        console.log("error en la función getApiRequestData - " + e);
        // this.presentAlertConfirm("Error en la función getApiRequestData",e.toString());
        this.sendErrorReport("getApiRequestData", e.toString());
      }

    },
    likeInteraction(likeValue) {
      try {
        var data = {
          title: this.title,
          artist: this.artist,
          type: this.type,
          location: this.location,
          stream: this.clientName,
          token: this.token,
          lat: 0,
          lon: 0,
          time: this.player.currentTime,
          liked: likeValue
        };
        // console.log(data);
        // this.liked = likeValue;
        // console.log("likeValue: " + this.liked)
        axios.post("https://vybroo.net/survey", data)
          .then(data => {
            console.log(data)
            this.liked = likeValue;
            this.emitter.emit("rep-liked", this.liked);
            console.log("likeValue: " + this.liked)
          })
          .catch(error => {
            console.error("Error en la post de like", e.toString());
            // this.presentAlertConfirm("Error en post de like",e.toString());
            this.sendErrorReport("likeInteraction", e.toString());
          });
      } catch (e) {
        console.error("ha ocurrido un error en la función likeInteraction - " + e);
        // this.presentAlertConfirm("Error en la función likeInteraction",e.toString());
        this.sendErrorReport("likeInteraction", e.toString());
      }
    },
    async presentAlertConfirm(headerAlert, messageAlert) {
      try {
        const alert = await alertController
          .create({
            header: headerAlert,
            message: messageAlert,
            buttons: ['Ok'],
          });
        return alert.present();
      } catch (e) {
        console.error("ha ocurrido un error en la función presentAlertConfirm - " + e);
        // this.presentAlertConfirm("Ha ocurrido un error en la función presentAlertConfirm",e.toString());
        this.sendErrorReport("presentAlertConfirm", e.toString());
      }
    },
    async presentAlertCustom(header, message, okFunction) {
      try {
        const alert = await alertController
          .create({
            header: header,
            message: message,
            backdropDismiss: false,
            buttons: [
              {
                text: 'Ok',
                handler: () => {
                  okFunction();
                }
              }
            ]
          });
        return alert.present();
      } catch (e) {
        console.error("ha ocurrido un error en la función presentAlertCustom - " + e);
        // this.presentAlertConfirm("Ha ocurrido un error en la función presentAlertCustom",e.toString());
        this.sendErrorReport("presentAlertCustom", e.toString());
      }
    },
    async presentAlertRefresh(headerAlert, messageAlert) {
      try {
        if (this.alertRefreshPresented == false) {
          const alert = await alertController
            .create({
              header: headerAlert,
              message: messageAlert,
              backdropDismiss: false,
              buttons: [
                {
                  text: 'Actualizar',
                  handler: () => {
                    this.$router.go();
                  }
                },
              ]
            });
          this.alertRefreshPresented = true;
          return alert.present();
        }
      } catch (e) {
        console.error("ha ocurrido un error en la función presentAlertRefresh - " + e);
        // this.presentAlertConfirm("Ha ocurrido un error en la función presentAlertRefresh",e.toString());
        this.sendErrorReport("presentAlertRefresh", e.toString());
      }
    },
    isJson(str) {
      try {
        const parsedStr = JSON.parse(str);
        return parsedStr;
      } catch (e) {
        return str;
      }
    },
    checkOnline() {
      try {
        if (window.navigator.onLine) {
          this.enLinea = 1;
          // console.log("En linea...");
        } else {
          this.enLinea = 2;
          // console.log("Fuera de linea, reintentando...");
        }
      } catch (e) {
        console.error("ha ocurrido un error en la función checkOnline - " + e);
        // this.presentAlertConfirm("Error en la función checkOnline",e.toString());
        this.sendErrorReport("checkOnline", e.toString());
      }
    },
    updateClientData() {
      try {
        // console.log("------------------ updateClientData ------------------");
        // console.log(this.clientData);
        if (this.clientData.id && this.clientData.id > 0) {

          const clientId = { id: this.clientData.link };
          axios.post("https://vybroo.net/client", clientId)
            .then(response => {
              if (response.data.error == true || response.data.disabled == true) {
                //funcion para redirigir al login
                const okFunction = () => {
                  Storage.clear();
                  menuController.close('menustudio');
                  this.$router.replace("/login/loginPage");
                }
                if (response.data.error == true) {
                  this.presentAlertCustom("El canal ha sido desactivado", "", okFunction);
                  this.pause();
                }
                else if (response.data.disabled == true) {
                  this.presentAlertCustom("El canal ha sido deshabilitado", "", okFunction);
                  this.pause();
                }
                else this.presentAlertCustom("Aviso", "No se ha podido encontrar el canal " + this.mLoginInput.toLowerCase() + ".", okFunction);
              } else {
                // console.log("Canal encontrado");
                this.setClientData("clientData", response.data);

                //CAMBIOS EN PERMISSIONS
                if (JSON.stringify(this.clientData.permissions) !== JSON.stringify(response.data.permissions)) {
                  console.log("Se detectaron cambios en Permissions, se solicitará actualizar - clientData:" + JSON.stringify(this.clientData.permissions) + " clientApi:" + JSON.stringify(response.data.permissions));
                  this.presentAlertRefresh("Recarga necesaria", "Se realizaron cambios en la configuración del canal por lo cual es necesario volver a cargar el reproductor.");
                  this.listenedChanges.permissions = this.clientData.permissions;
                  // this.emitter.emit("permissions", this.clientData.permissions);
                }

                //CAMBIOS EN LOS CONTROLES DE CALIDAD
                if (this.clientData.quality_controls != response.data.quality_controls) {
                  this.sound.controls = response.data.quality_controls;
                  this.emitter.emit("quality-controls", this.sound.controls);
                }

                //VERIFICAR QUE SE TENGA LA VERSIÓN (clientVersion) más actualizada, solo si el cliente tiene versión entre su data
                //si esta usando navegador y no tiene la versión más actualizada, se le solicitará actualizar
                if (response.data.version && (this.clientData.version != response.data.version) && navigator.userAgent.includes("Mozilla")) {
                  console.log("Se detectaron cambios en la versión del canal, se solicitará actualizar - clientData:" + this.clientData.version + " clientApi:" + response.data.version);
                  this.presentAlertRefresh("Actualización disponible", " Por favor actualice la aplicación para utilizar la versión más reciente (v" + response.data.version + ").");
                }

                //ACTUALIZACIÓN DE LA VARIABLE clientData
                this.clientData = this.isJson(response.data);

                //CAMBIOS EN IMAGEN DEL CANAL
                if (this.clientData.image) {
                  this.listenedChanges.image = this.clientData.image;
                  this.clientImageSrc = 'https://pub-3b7d9c13a5754ebeaa0345eb87aa22c1.r2.dev/images/' + this.clientData.image;
                }
                //CAMBIOS EN COLOR DE FONDO DEL CANAL
                if (this.clientData.color) {
                  this.listenedChanges.color = this.clientData.color;
                  this.clientColor = "#" + this.clientData.color;
                }

                this.listenedChanges.save_m_variant = this.clientData.save_m_variant;
                // this.getStorageClientData("clientData");
                // console.log("---- clientData update successful ----")
              }
              // console.log(response.data);
              // console.log("------------------ updateClientData ------------------");

            })
            .catch(error => {
              console.error("Axios error en updateClientData", error);
            });
        }

      } catch (e) {
        console.error("ha ocurrido un error en la función updateClientData - " + e);
        // this.presentAlertConfirm("Error en la función updateClientData",e.toString());
        this.sendErrorReport("updateClientData", e.toString());
      }
    },
    setClientData(keyName, dataValue) {
      try {
        Storage.set({
          key: keyName,
          value: JSON.stringify(dataValue),
        })
          .then
        // console.log("Asignado a storage exitosamente - " + keyName);
      } catch (e) {
        console.error("ha ocurrido un error en la función setClientData - " + e);
        // this.presentAlertConfirm("Ha ocurrido un error en la función setClientData",e.toString());
        this.sendErrorReport("setClientData", e.toString());
      }
    },
    async getStorageClientData(keyName) {
      try {
        const ret = await Storage.get({ key: keyName });
        this.clientData = this.isJson(ret.value);
        // this.emitter.emit("g-data", this.clientData);
        // console.log("Recuperado de storage - " + keyName);
      } catch (e) {
        console.error("ha ocurrido un error en la función getStorageClientData - " + e);
        // this.presentAlertConfirm("Ha ocurrido un error en la función getStorageClientData",e.toString());
        this.sendErrorReport("getStorageClientData", e.toString());
      }
    },
    skipToEnd() {
      try {
        const audio = this.player;
        if (!audio.paused) {
          audio.currentTime = audio.duration - 10;
        }
      } catch (e) {
        console.error("ha ocurrido un error en la función skipToEnd - " + e);
        // this.presentAlertConfirm("Error en la función skipToEnd",e.toString());
        this.sendErrorReport("skipToEnd", e.toString());
      }
    },
    async checkContest() {
      try {
        //hacer axios post a https://vybroo.net/contest con el id del cliente
        //si el cliente tiene un concurso activo, se debe mostrar el modal de concurso

        // console.log("Revisando si hay concursos...");
        const stream = { id: this.clientData.id };

        await axios.post("https://vybroo.net/contest", { stream: this.clientData.id })
          .then(response => {
            // console.log("contest response");
            // console.log(response.data);
            if (!response.data.success) {
              console.log("No hay concursos o hubo un error");
              console.log(response.data);
              return;
            }
            if (response.data.success && response.data.contest) {
              var optionsArray = null;
              var optionsLength = null;
              if (response.data.contest.contest && response.data.contest.contest.options) {
                // console.log("Hay opciones para el concurso (contest.contest.options)")
                this.contest = response.data.contest.contest;
                optionsArray = response.data.contest.contest.options ? response.data.contest.contest.options : response.data.contest.options;
                // console.log(optionsArray);
                optionsLength = optionsArray.length;
              } else {
                // console.log("No hay opciones para el concurso (no es contest.contest.options)");
                if (response.data.contest && response.data.contest.options) {
                  // console.log("Hay opciones para el concurso (contest.options)");
                  this.contest = response.data.contest;
                  optionsArray = response.data.contest.options;
                  // console.log(optionsArray);
                  optionsLength = optionsArray.length;
                } else {
                  // console.log("tampoco hay opciones para el concurso (no es contest.options)");
                  return;
                }
              }
              if (optionsLength && optionsLength == 2) {
                console.log("Hay un concurso activo");
                //hay un concurso activo, verificar en el storage si no se ha votado, si no se ha votado, mostrar un modal para votar que muestre el nombre de cada opción y su imagen
                this.contest.options = optionsArray;
                console.log("Revisando si ya se votó en el Storage...")
                this.checkStorageContestVote(this.contest.id);
                return;
                console.log("NO SE ENCONTRÓ CONTEST ID");

              }
            }

          })
          .catch(error => {
            console.error("Axios error en checkContest", error);
          });
      } catch (e) {
        console.error("ha ocurrido un error en la función checkContest - " + e);
        // this.presentAlertConfirm("Error en la función checkContest",e.toString());
        this.sendErrorReport("checkContest", e.toString());
      }
    },
    async checkStorageContestVote(contestId) {
      try {
        const keyStorage = 'contest_vote:' + contestId;
        console.log("key: " + keyStorage);
        await Storage.get({ key: keyStorage }).then((value) => {
          console.log(value);
          if (value.value) {
            console.log("Ya se votó en el Storage, no se mostrará el modal de concurso");
            console.log(value.value);
            this.concursoActivo = 'concursoInactivo';
          } else {
            console.log("No se ha votado en el Storage, se mostrará el modal de concurso");
            console.log(value.value);
            //modificar la url de las imágenes
            this.contest.options.forEach(element => {
              element.image = "https://pub-3b7d9c13a5754ebeaa0345eb87aa22c1.r2.dev/images/" + element.image;
            });
            console.log("Imprimiendo contest nuevo");
            console.log(this.contest);
            this.concursoActivo = 'concursoActivo';
            console.log("Mostrando modal de concurso");
            this.showContestModal();
          }
        });
      } catch (e) {
        console.error("ha ocurrido un error en la función checkStorageContestVote - " + e);
        // this.presentAlertConfirm("Error en la función checkStorageContestVote",e.toString());
        this.sendErrorReport("checkStorageContestVote", e.toString());
      }
    },
    async showContestModal() {
      try {
        if (this.modalisopen == true) {
          return;
        }
        if (this.modalWasClosed == true) {
          return;
        }
        this.modalisopen = true;
        const modal = await modalController.create({
          component: contestModal,
          //backdropDismiss: false,
          componentProps: {
            contestParam: this.contest,
          },
        });
        modal.onDidDismiss().then((dataReturned) => {
          if (dataReturned !== null) {
            this.modalisopen = false;
            this.modalWasClosed = true;
            console.log("Modal cerrado");
          }
        });
        return modal.present();
      } catch (e) {
        console.error("ha ocurrido un error en la función showContestModal - " + e);
        // this.presentAlertConfirm("Error en la función showContestModal",e.toString());
        this.sendErrorReport("showContestModal", e.toString());
      }
    },

    async showContestModalButton() {
      this.modalWasClosed = false;
      this.showContestModal();
    },

    //regresar solo el numero de la versión del navegador
    getBrowserVersionNumber() {
      var ua = navigator.userAgent, tem,
        M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
      if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return 'IE ' + (tem[1] || '');
      }
      if (M[1] === 'Chrome') {
        tem = ua.match(/\bOPR|Edge\/(\d+)/)
        if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
      }
      M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
      if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
      return M.join(' ');
    },

    async showVideoModal(src) {
      try {
        console.log("showVideoModal | videoOn:" + this.videoOn);
        if (this.videoOn == true) {
          return;
        }
        this.videoOn = true;
        const modal = await modalController.create({
          component: RepModal,
          //backdropDismiss: false,
          cssClass: 'full_modal',
          componentProps: {
            title: 'RepVideo',
            video: src,
          },
          keyboardClose: false,
          backdropDismiss: false,
        });
        modal.onDidDismiss().then((dataReturned) => {
          if (dataReturned !== null) {
            this.videoOn = false;
            console.log("Modal cerrado");
          }
        });
        return modal.present();
      } catch (e) {
        console.error("ha ocurrido un error en la función showVideoModal - " + e);
        // this.presentAlertConfirm("Error en la función showVideoModal",e.toString());
        this.sendErrorReport("showVideoModal", e.toString());
      }
    },
    async getip() {
      try {
        const response = await axios.get('https://api.ipify.org?format=json');
        var ip = response.data.ip;
        this.ip = ip;
        this.emitter.emit("ip", this.ip);
        console.log("GETIP ", ip);
      } catch (error) {
        console.log("GETIP 1 Error en función getConnections de Conexiones: ", error.message);
      }
    },
    async wakeLockOn() {
      try {
        if (this.platform == "android" || this.platform == "ios") {
          console.log("No Iniciando WakeLock...");
        }else{
          console.log("Iniciando WakeLock...");
              try {
                  this.wakeLock = await navigator.wakeLock.request('screen');
                  this.wakeLock.addEventListener('release', () => {
                    setTimeout(() => { console.log("Finalizando WakeLock..."); }, 1000);
                  });
              } catch (err) {
                  console.error("wakeLock:err:",err);
                  this.wakeLock = null;
              }
        }
      } catch (e) {
        console.error("ha ocurrido un error en la función wakeLockOn - " + e);
      }
    },

  },
  mounted: function () {
    this.getip();
    try {
      this.platform = Capacitor.getPlatform();
      this.isLoaded = true;

      //calculo de hora local
      this.getServerTime();

      this.emitter.on("sonara", (data) => {
        this.sonara = data;
      });

      // console.log("Hora local en Zona Horaria: " + this.horaLocal);
      this.emitter.on("send-error-report", (functionName, error) => {
        console.log("send-error-report recibido");
        this.sendErrorReport(functionName, error);
      });
      this.emitter.on("rep-func-play", play => {
        console.log("play emitter recibido");
        this.play();
      });
      this.emitter.on("rep-func-pause", pause => {
        this.pause();
      });
      this.emitter.on("rep-func-quality-options", quality => {
        this.showQualityOptionsButton();
      });
      this.emitter.on("rep-func-volume", volume => {
        // console.log("volume emitter recibido");
        this.changeVolume(volume);
      });
      this.emitter.on("rep-func-likeInteraction", likeValue => {
        this.likeInteraction(likeValue);
      });
      this.emitter.on("rep-func-kill", kill => {
        this.killAudio(); this.killAudioDemanda();
        console.log("kill: " + kill);
      });
      this.emitter.on("concursoModal", status => {
        if (status == 'votado') {
          this.concursoActivo = 'concursoInactivo';
        }
        console.log("ConcursoStatus: " + status);
      });
      this.emitter.on("videoModalEnd", status => {
        this.next();
        console.log("videoModalEnd: " + status);
      });
      this.emitter.on("videoOn", status => {
        this.videoOn = status;
        console.log("emitter videoOn desde tabs: " + status);
      });
      this.emitter.on("fullScreenOn", status => {
        this.fullScreenOn = status;
        //console.log("emitter fullScreenOn desde TABREP: " + status);
      });
      this.emitter.on("hora_actual", hora => {
        this.horaActual = hora;
        // console.log("emitter hora_actual desde tabs: " + hora);
      });

      // console.log("Iniciando WakeLock...");
      // PowerManagement.acquire()
      // .then(res => {
      //   console.log('Wakelock aquire')
      // })
      // .catch(res => console.log('Error Wakelock acquired - ', res));

      // PARÁMETROS EN EL URL
      const queryString = window.location.search;
      const urlParametros = new URLSearchParams(queryString);
      this.urlParams.sucursal = urlParametros.get('sucursal');
      this.urlParams.sucursalName = urlParametros.get('sucursalName');
      this.urlParams.categoria = urlParametros.get('categoria');
      this.urlParams.variante = urlParametros.get('variante');
      // // const sucursalUrl = urlParametros.get('sucursal');
      // console.log("URL PARAMETRO SUCURSAL: " + this.urlParams.sucursal);
      // this.currentSucursal = this.urlParams.sucursal;
      // this.emitter.emit("rep-currentSucursal", this.currentSucursal);
      // console.log("URL PARAMETRO VARIABLE: " + this.currentSucursal);
      // console.log(this.currentlyTimer);
      this.player.autoplay = false;
      this.playerAudioDemanda.autoplay = false;

      menuController.enable(true, "menustudio");
      this.getClientData("clientData");
      setTimeout(() => {
        this.updateClientData();
        this.checkContest();
        this.imHere();
      }, 10000);
      // window.setInterval(() => {
      //   this.sendErrorReport("Carga de la app", "OK");
      // }, 10000);
      window.setInterval(() => {
        this.updateClientData();
        this.checkContest();
      }, 360000);
      window.setInterval(() => {
        this.imHere();
      }, 150000);
      console.info("%c🖥️ VERSIÓN DE APP: " + this.updateInfo, "color: green; font-weight: bold; background-color: #eee; padding: 5px; border-radius: 5px; font-size: 14px; border: 2px solid green;");

      window.setInterval(() => {
        if (this.running500msInterval == false) {
          this.running500msInterval = true;
          this.currentlyTimer = this.millisToMinutesAndSeconds(Math.round(this.player.currentTime) * 1000);
          this.songProgress = this.player.currentTime / this.player.duration;
          // si songProgress tiene 5 segundos o más, reiniciar el contador de playErrorCount
          if (this.player.currentTime > 5) {
            // console.log("this.player.currentTime: " + this.player.currentTime);
            if (this.playErrorCount > 0 && this.type == "song") {
              this.playErrorCount = 0;
              console.log("Reiniciando playErrorCount: " + this.playErrorCount);
            }
            if (this.tokenErrorCount > 0 && this.type == "song") {
              this.tokenErrorCount = 0;
              console.log("Reiniciando tokenErrorCount: " + this.tokenErrorCount);
            }

            // next de pruebas
            // this.next();
          }
          this.sound.previousTime = this.player.currentTime;
          this.emitter.emit("rep-currentlyTimer", this.currentlyTimer);
          this.emitter.emit("rep-songProgress", this.songProgress);
          // console.log("currentlyTimer:" + this.currentlyTimer);
          if (this.player.duration)
            this.totalTimer = this.millisToMinutesAndSeconds(Math.round(this.player.duration) * 1000);
          this.emitter.emit("rep-totalTimer", this.totalTimer);
          if (this.clientData.video_enabled == false) {
            setTimeout(() => {
              this.getApiRequestData();
              if (!this.globalPlay && Capacitor.getPlatform() != 'ios') {
                //si el dispositivo no es iOS, se reproduce automáticamente
                this.play();
                this.globalPlay = true;
                this.isPlaying = true;
                this.emitter.emit("rep-globalPlay", this.globalPlay);
                this.emitter.emit("rep-isPlaying", this.isPlaying);
              }
              //si el navegador está desactualizado mostrar alerta
              // const browserVersion = this.getBrowserVersionNumber();
              // if (Capacitor.getPlatform() == 'web' && browserVersion < 80) {
              //   this.presentAlertCustom("Aviso","Para garantizar una mejor experiencia, actualiza tu navegador a la última versión.");
              //   console.log("Versión de navegador: " + browserVersion);
              // } else {
              //   console.log("Navegador actualizado");
              //   console.log("Versión de navegador: " + browserVersion);
              // }
            }, 2000);
          }
          menuController.enable(true, "configMenu");
          this.running500msInterval = false;
        } else {
          console.log("500ms interval is already running");
        }
      }, 500);
      window.setInterval(() => {
        this.getApiRequestData();
        this.checkOnline();
      }, 2000);
      // this.presentAlertConfirm("Información",JSON.stringify({"currentSucursal:": this.currentSucursal, "currentCategoria": this.currentCategoria, "playerAudioDemandaSource:": this.playerAudioDemandaSource}))
      this.listenerOnPause();
      this.listenerOnPlay();
      this.listenerOnEnded();
      this.listenerOnError();
      if (Capacitor.getPlatform() == 'ios') {
        const okFunction = () => {
          console.log("okFunction");
          this.play();
        };
        // this.presentAlertCustom("Aviso","Para garantizar el correcto funcionamiento de la aplicación es necesario que esta se encuentre siempre funcionando en primer plano y con la pantalla encendida.", okFunction);
        // this.presentAlertConfirm("Aviso","Para garantizar el correcto funcionamiento de la aplicación es necesario que esta se encuentre siempre funcionando en primer plano y con la pantalla encendida.");
      };
    } catch (e) {
      console.error("ha ocurrido un error en mounted - " + e);
      // this.presentAlertConfirm("Error en la función mounted",e.toString());
      this.sendErrorReport("error-mounted", e.toString());
    }

    window.setInterval(() => {
      this.speedTestRun();
    }, 3 * 60 * 60 * 1000); // 3 horas en milisegundos
    setTimeout(() => {
      this.speedTestRun();
    }, 30 * 1000); // 30 segundos en milisegundos

    this.wakeLockOn();

  },
  created() {
    try {
      // POWER MANAGEMENT
      // this.powerManagement.acquire()
      // .then(
      //   this.presentAlertConfirm("Power management acquired")
      // )
      // .catch(
      //   this.presentAlertConfirm("Power management error - ", onError)
      // );
      if (Capacitor.getPlatform() != 'web') {
        // PowerManagement.acquire(function() {
        //   console.log('Wakelock acquired');
        // }, function() {
        //   console.log('Failed to acquire wakelock');
        // });
      }

      // console.log("Navegador o plataforma: "+ Capacitor.getPlatform());
      if (Capacitor.getPlatform() != 'ios') {
        App.addListener('appStateChange', state => {
          if (!state.isActive) { // if isActive is true, App got sent to foreground, if it is false, it got sent to the background
            // console.log("APP ON BACKGROUND --------------------------------------------------")
            BackgroundMode.enable();
            BackgroundMode.setEnabled(true);
            BackgroundMode.on('activate', () => {
              BackgroundMode.disableBatteryOptimizations();
              BackgroundMode.disableWebViewOptimizations();
            })
          } else {
            // console.log("APP ON SCREEN --------------------------------------------------")
            // BackgroundMode.disable();
            // BackgroundMode.setEnabled(false);
            BackgroundMode.enable();
            BackgroundMode.setEnabled(true);
            BackgroundMode.on('activate', () => {
              BackgroundMode.disableBatteryOptimizations();
              BackgroundMode.disableWebViewOptimizations();
            })
          }
        });
        // App.addListener('backButton', ({canGoBack}) => {
        //   if(!canGoBack){
        //     console.log('Cant go back')
        //     this.$router.push('/tabs/tabReproductor')
        //   } else {
        //     console.log('Can go back')
        //   }
        // });
        // App.addListener('appStateChange', ({ isActive }) => {
        //   // console.log('App state changed. Is active?', isActive);
        // });

        Network.addListener('networkStatusChange', status => {
          console.log('Network status changed', status.connected);
          console.log(status);
          if (status.connected == false) {
            Toast.show({ text: 'AVISO: Se perdió la conexión a internet.', duration: 'long' });
            this.title = "Sin conexión";
            this.artist = "Por favor verifique su red";
            this.audio_id = '0';
            this.emitter.emit("rep-title", this.title);
            this.emitter.emit("rep-artist", this.artist);

          } else if (status.connected == true) {
            if (this.networkStatus.connected == false) {
              Toast.show({ text: 'Conexión a internet establecida.', duration: 'long' });
            }
            this.title = this.bkTitle;
            this.artist = this.bkArtist;
            this.audio_id = '0';
            this.emitter.emit("rep-title", this.title);
            this.emitter.emit("rep-artist", this.artist);
          }
          this.networkStatus = status;
          // console.log('Network status:', this.networkStatus.connected);
        });
        // const logCurrentNetworkStatus = async () => {
        //   const status = await Network.getStatus();

        // };
        // logCurrentNetworkStatus();
      }
    } catch (e) {
      console.error("Error en la función created - ", e.toString());
      this.presentAlertConfirm("Error en la función created - ", e.toString());
    }

  }
}
</script>

<style scoped>
* {
  font-family: 'Montserrat', sans-serif;
}
/*=============================================================*/
/* VBACK ON */
/*=============================================================*/
.loaderOutDiv {
  position: fixed;
  right: 30px;
  bottom: 10px;
  animation: vback 2s linear infinite;
  z-index: 2;
}
@keyframes vback {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-3px);
  }
  100% {
    transform: translateY(0);
  }
}
.loaderOutDiv .vBackMessage {
  display: none;
  position: fixed;
  right: 5px;
  bottom: 32px;
  z-index: 2;
  color: rgb(198, 171, 123);
  border: solid rgb(108, 76, 11) 1px;
  font-size: 12px;
  text-align: center;
  background: rgb(47, 40, 5);
  border-radius: 3px;
  padding: 2px 5px;
  width: 120px;
  opacity: 0.9;
}
.loaderOutDiv:hover .vBackMessage {
  display: block;
}
.loaderOutDiv:hover .vBackMessage::before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid rgb(108, 76, 11);
  bottom: -8px;
  left: 88%;
  transform: translateX(-50%);
}

.loaderOutDiv .vbackimg {
  width: 36px;
  height: 36px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loaderr {
  position: relative;
  margin: auto;
  box-sizing: border-box;
  background-clip: padding-box;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid rgba(255, 255, 255, 0.1);
  -webkit-mask: linear-gradient(
    rgba(24, 24, 24, 0.2),
    rgba(24, 24, 24, 0.9) 90%
  );
  transform-origin: 50% 60%;
  transform: perspective(200px) rotateX(66deg);
}

.loaderr:before,
.loaderr:after {
  content: '';
  position: absolute;
  margin: -4px;
  box-sizing: inherit;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  opacity: 0.05;
  border: inherit;
  border-color: transparent;
  animation: spinner-spin 1.2s cubic-bezier(0.6, 0.2, 0, 0.8) infinite,
    spinner-fade 1.2s linear infinite;
}

.loaderr:before {
  border-top-color: #66e6ff;
}

.loaderr:after {
  border-top-color: #f0db75;
  animation-delay: 0.3s;
}

@keyframes spinner-spin {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinner-fade {
  20% {
    opacity: 0.1;
  }

  40% {
    opacity: 1;
  }

  60% {
    opacity: 0.1;
  }
}

/*=============================================================*/
/* VBACK ON */
/*=============================================================*/

.concursoNotification {
  position: fixed;
  width: 50px;
  height: 50px;
  background-color: #f7f7f7;
  border-radius: 50px;
  left: 20px;
  bottom: 20px;
  border-style: solid;
  border-width: 1px;
  border-color: #dcdcdc;
  animation: slidebg 50s linear infinite;
  background-image: linear-gradient(
    90deg,
    #00c0ff 0%,
    #00ff22 20%,
    #ffcf00 40%,
    #fd1212 60%,
    #ba12fd 80%,
    #00c0ff 100%
  );
  z-index: 1;
}

@keyframes slidebg {
  to {
    background-position: 200vw;
  }
}

.concursoNotification::before {
  position: absolute;
  content: '';
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  background-color: #f7f7f7;
  left: 50%;
  top: 50%;
  border-radius: 100%;
  transform: translate(-50%, -50%);
  z-index: -1;
  opacity: 0.95;
}

.concursoNotification:hover {
  scale: 1.2;
}

.concursoNotification .tooltip {
  display: none;
}

.concursoNotification .tooltip {
  box-shadow: 0px 0px 1px #fff;
  background: #1c1c1c;
  position: absolute;
  right: 0;
  top: 50%;
  padding: 7px 10px;
  transform: translate(calc(100% + 15px), -50%);
  font-size: 14px;
  width: 145px;
  scale: 1;
  animation: tooltip 0.5s;
  border-radius: 2px;
  color: white !important;
}

@keyframes tooltip {
  0% {
    scale: 0.7;
    opacity: 0;
  }

  20% {
    scale: 0.7;
    opacity: 0;
  }

  100% {
    scale: 1;
    opacity: 1;
  }
}

.concursoNotification .tooltip:before {
  content: '';
  border-style: solid;
  position: absolute;
  left: -9px;
  border-color: transparent #1c1c1c transparent transparent;
  border-width: 5px 10px 5px 0px;
  top: calc(50% - 5px);
}

.concursoNotification:hover .tooltip {
  display: block;
}

.concursoNotification:hover::before {
  opacity: 0.8;
}

.concursoNotification ion-img {
  animation: notification_ticket 5s linear infinite;
}

@keyframes notification_ticket {
  0% {
    transform: rotate(0deg);
  }

  80% {
    transform: rotate(0deg);
  }

  85% {
    transform: rotate(15deg);
  }

  90% {
    transform: rotate(-15deg);
  }

  95% {
    transform: rotate(15deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.concursoActivo {
  display: block;
}

.concursoInactivo {
  background-color: red;
  display: none;
}

.headerTop {
  background-color: black;
}

.playerImage {
  width: 80vw;
  height: 80vw;
  border-radius: 15px;
  margin: 20px auto 10px auto;
}

.songInfoCol {
  padding-left: 3vh;
  /* centrar contenido verticalmente sin display flex*/
  margin: auto;
}

.likeCol {
  margin: auto;
  text-align: right;
  padding-right: 3vh;
}

.progressBarCol {
  padding-left: 3vh;
  padding-right: 3vh;
}

.songTimeCol {
  padding-left: 3vh;
  color: white;
  margin: 0px;
}

.songTimeRemainingCol {
  margin: auto;
  text-align: right;
  padding-right: 3vh;
  color: white;
}

.songTimeCol .ios {
  color: white;
}

.songTimeRemainingCol .ios {
  color: white;
}

.playPauseCol {
  margin: auto;
  text-align: center;
  margin-top: -1.5vh;
  min-height: 90px !important;
}

.likeButton,
.dislikeButton {
  --padding-start: 18px;
  --padding-end: 18px;
}

.songName {
  font-weight: bold;
  font-size: 4vw;
  margin-bottom: 1.5vh;
  margin-top: 5px;
}

.playlistName {
  font-size: 3.8vw;
  margin-top: 0px;
}

.songTime,
.songTimeRemaining {
  font-size: 3vw;
  margin-top: 0;
}

.playPauseButton {
  font-size: 35px;
  border-radius: 100%;
  height: 80px;
  margin-bottom: 25px;
}

.volumeButton {
  font-size: 20px;
  border-radius: 100%;
  height: 80px;
  margin: auto;
  margin-left: 20px;
  padding: auto;
  /* transparencia */
  opacity: 0.7;
  /* fijo a la izquierda */
  position: fixed;
  left: 0;
}

.optionsButton {
  font-size: 25px;
  border-radius: 100%;
  height: 80px;
  margin: auto;
  margin-right: 20px;
  padding: auto;
  /* transparencia */
  opacity: 0.7;
  /* fijo a la derecha */
  position: fixed;
  right: 0;
}

.songName,
.playlistName {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
}

.songName:hover > *,
.playlistName:hover > * {
  display: inline-block;
  position: relative;
  animation: 5s linear 0s infinite move;
  min-width: 100%;
  text-overflow: initial;
  max-width: auto;
}

@keyframes move {
  0% {
    transform: translateX(0%);
    left: 0%;
  }

  20% {
    transform: translateX(0%);
    left: 0%;
  }

  100% {
    transform: translateX(-100%);
    left: 100%;
  }
}

.songName,
.playlistName {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
}

.songName:hover > *,
.playlistName:hover > * {
  display: inline-block;
  position: relative;
  animation: 5s linear 0s infinite move;
  min-width: 100%;
  text-overflow: initial;
  max-width: auto;
}

@keyframes move {
  0% {
    transform: translateX(0%);
    left: 0%;
  }

  20% {
    transform: translateX(0%);
    left: 0%;
  }

  100% {
    transform: translateX(-100%);
    left: 100%;
  }
}

.playerImage2_out {
  width: 100%;
  height: 100%;
  display: none;
}

.playerImage2 {
  width: 100%;
  height: 100%;
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: center center;
  max-width: 700px;
  max-height: 700px;
  margin: auto;
}

.nextCol {
  margin: auto;
  text-align: center;
  padding-right: 3vh;
  padding-left: 3vh;
  margin-top: -1.5vh;
  display: none;
}

.nextButton {
  height: 50px;
  margin: auto;
  text-align: center;
  width: auto;
  transition: all 0.1s ease-in-out;
}

.nextButton:hover {
  transform: scale(1.05);
}

.nextButtonImg {
  filter: invert(100%);
  width: 100%;
  height: 100%;
}

@media only screen and (min-width: 767px) {
  .reproductor_small {
    display: none;
  }

  .playerImage {
    display: none;
  }

  .playerImage2_out {
    width: 100%;
    height: 100%;
    display: grid;
  }

  .playerImage2 {
    min-width: 60%;
    min-height: 60%;
    background-size: 90%;
  }
}

@media only screen and (min-width: 1125px) {
}

@media only screen and (max-width: 480px) {
  .likeButton,
  .dislikeButton {
    --padding-start: 9px;
    --padding-end: 9px;
  }
}

@media only screen and (max-width: 360px) {
  .likeButton,
  .dislikeButton {
    --padding-start: 5px;
    --padding-end: 5px;
  }
}

@media only screen and (max-height: 600px) {
  .songInfoCol {
    height: 70px;
  }

  .songName {
    margin-bottom: 10px;
    margin-top: 0;
  }

  .playlistName {
    margin: 10px 0 0 0;
  }

  .playerImage {
    width: 75vw;
    height: 75vw;
    border-radius: 1.5vh;
    margin: 3vh auto 1vh auto;
  }
}
</style>
